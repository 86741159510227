import React, { useEffect, useState, useRef } from 'react';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { IconButton, Tooltip, Button } from '@mui/material';
import { Canvas, Image } from 'fabric';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RecordIcon from '@mui/icons-material/RecordVoiceOver';

function Video({ canvas , canvasRef}) {

    const [videoSrc, setVideoSrc] = useState(null);
    const [fabricVideo, setFabricVideo] = useState(null);
    const [recordingChunks, setRecordingChunks] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [isReadyToExport, setIsReadyToExport] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [uploadMessage, setUploadMessage] = useState('');
    const [recordingTime, setRecordingTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const fileInputRef = useRef(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const recordingIntervalRef = useRef(null);

    const handleVideoUpload =  (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoadedPercentage(0);
            setVideoSrc(null);
            setUploadMessage('');

            const url = URL.createObjectURL(file);
            setVideoSrc(url);

            const videoElement = document.createElement('video');
            videoElement.src = url;
            videoElement.crossOrigin = 'anonymous';

            videoElement.addEventListener('loadeddata', () => {
                const videoWidth = videoElement.videoWidth;
                const videoHeight = videoElement.videoHeight;
                videoElement.width = videoWidth;
                videoElement.height = videoHeight;

                const canvasWidth = videoWidth;
                const canvasHeight = videoHeight;

                const scale = Math.min(
                    canvasWidth / videoWidth,
                    canvasHeight / videoHeight
                );

            canvas.renderAll();

            const fabricImage = new Image(videoElement, {
                left: 0, 
                top: 0, 
                scaleX: scale, 
                scaleY: scale
            });

            setFabricVideo(fabricImage);
            canvas.add(fabricImage);
            canvas.renderAll();

            setUploadMessage('Video uploaded successfully');

            setTimeout(() => {
                setUploadMessage('');
            }, 3000);
        });
        videoElement.addEventListener('progress', () => {
            if(videoElement.buffered.length > 0) {
                const bufferedEnd = videoElement.buffered.end(
                    videoElement.buffered.length - 1
                );
                const duration = videoElement.duration;
                if(duration > 0) {
                    setLoadedPercentage((bufferedEnd / duration) * 100);
                }
            }
        });

        videoElement.addEventListener('error', (event) => {
            console.error('Error loading video:', event);
            setUploadMessage('Error loading video');
        });
        videoRef.current= videoElement;
    };
    }

    const handlePlayPauseVideo = () => {
        if(videoRef.current) {
            if(videoRef.current.paused) {
                videoRef.current.play();
                videoRef.current.addEventListener('timeupdate', () => {
                    fabricVideo.setElement(videoRef.current);
                    canvas.renderAll();
                });
                setIsPlaying(true);
            } else {
                videoRef.current.pause();

                setIsPlaying(false);
            }
        }
    };


    const handleStopVideo = () => {
        if(videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
            canvas.renderAll();
        }
    };

    const handleVideoUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleStartRecording = () => {
        setRecordingChunks([]);
        const stream = canvasRef.current.captureStream();
        mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: 'video/webm; codecs=vp9',
        });
        
        mediaRecorderRef.current.ondataavailable = handleDataAvailable;
        mediaRecorderRef.current.onstop = () => {
            console.log("Enregistrement terminé, chunks:", recordingChunks.length);
        };
        
        mediaRecorderRef.current.start(1000);
        setIsRecording(true);

        canvas.getObjects().forEach(obj => {
            obj.hasControls = false;
            obj.selectable = true;
        });

        canvas.renderAll();
        setRecordingTime(0);

        recordingIntervalRef.current = setInterval(() => {
            setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
    };

    const handleStopRecording = () => {
        if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') {
            return;
        }

        mediaRecorderRef.current.requestData();
        
        setTimeout(() => {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setIsReadyToExport(true);

            canvas.getObjects().forEach(obj => {
                obj.hasControls = true;
            });

            canvas.renderAll();
            clearInterval(recordingIntervalRef.current);
            
            console.log("Arrêt de l'enregistrement, nombre de chunks:", recordingChunks.length);
        }, 200);
    };
    
    const handleDataAvailable = (event) => {
        console.log('Données reçues:', event.data.size);
        if(event.data.size > 0) {
            setRecordingChunks(prev => {
                const newChunks = [...prev, event.data];
                console.log('Nombre total de chunks:', newChunks.length);
                return newChunks;
            });
        }
    };

    const handleExportVideo = () => {
        const blob = new Blob(recordingChunks, {type: 'video/webm'});
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'canvas-visiblia-recording.webm';
        a.click();

        URL.revokeObjectURL(url);
        setIsReadyToExport(false);
        setRecordingChunks([]);
    };
    
    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="video/mp4"
                onChange={handleVideoUpload}
            />
    
            <Tooltip title="Ajouter une vidéo">
                <IconButton color="primary" onClick={handleVideoUploadButtonClick}>
                    <VideoCameraBackIcon />
                </IconButton>
            </Tooltip>
    
            {videoSrc && (
    <div className="bottom transform darkmode">
        <div className="Toolbar">
            <Tooltip title={isPlaying ? 'Pause' : 'Lecture'}>
                <IconButton color="primary" onClick={handlePlayPauseVideo}>
                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
            </Tooltip>
            <Button variant="contained" color="primary" onClick={handleStopVideo}>
                Stop
            </Button>
        </div>

        <div className="Toolbar">
            <div className="progress-bar">
                <div
                    className="progress"
                    style={{ width: `${loadedPercentage}%` }}
                />
            </div>
            {uploadMessage && (
                <div className="upload-message">{uploadMessage}</div>
            )}
        </div>

        <div className="Toolbar">
            <Button
                onClick={isRecording ? handleStopRecording : handleStartRecording}
                variant="contained"
                color="primary"
            >
                {isRecording ? (
                    <>
                        <StopIcon /> End {new Date(recordingTime * 1000).toISOString().substr(11, 8)}
                    </>
                ) : (
                    <>
                        <RecordIcon /> Record
                    </>
                )}
            </Button>

            {isReadyToExport && (
                <Button
                    onClick={handleExportVideo}
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: '10px' }}
                >
                    Export Video
                </Button>
            )}
        </div>
    </div>
)}


        </>
    );
    
}

export default Video;