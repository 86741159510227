// PostCard.js
import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingOverlay from './LoadingOverlay';
import CompanyInfo from './CompanyInfo';
import CardActionsTopSection from './CardActionsTopSection';
import CardActionsBottomSection from './CardActionsBottomSection';
import PostContent from './PostContent';
import MediaPreview from './MediaPreview';
import CancelEditDialog from './CancelEditDialog';
import EditPostModal from './EditPostModal';
import { updateDraftPost, uploadMediaFiles } from '../../../services/firebase/draftPostService';
import { regeneratePartialPost } from '../../../services/OpenAi/generatePostService';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../config/firebaseConfig';

function PostCard({
  prompt,
  content,
  mediaFiles = [],
  companyName,
  companyLogoUrl,
  timestamp,
  isGenerating,
  selectedDate,
  isPublishing,
  isPublished,
  onPublishToFacebook,
  onDelete,
  onSchedule,
  onRegenerate,
  draftId,
  onUpdate,
  scroll = false,
  post = {},
  userId
}) {
  // États locaux
  const [isEditing, setIsEditing] = useState(false);
  const [editableContent, setEditableContent] = useState(content);
  const [tempMediaFiles, setTempMediaFiles] = useState(mediaFiles);
  const [addedMediaFiles, setAddedMediaFiles] = useState([]);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isPartialGenerating, setIsPartialGenerating] = useState(false);
  const [editableSelectedDate, setEditableSelectedDate] = useState(selectedDate);
  const [selectedPlatforms, setSelectedPlatforms] = useState(
    post?.selectedPlatforms || {
      facebook: false,
      instagram: false,
      twitter: false,
      linkedin: false
    }
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Synchronisation des états avec les props
  useEffect(() => {
    setEditableContent(content);
    setTempMediaFiles(mediaFiles);
    setEditableSelectedDate(selectedDate);
  }, [content, mediaFiles, selectedDate]);

  useEffect(() => {
    if (post?.selectedPlatforms) {
      setSelectedPlatforms(post.selectedPlatforms);
    }
  }, [post?.selectedPlatforms]);

  // Gestionnaires d'événements
  const handleAddMedia = useCallback((newFiles) => {
    setTempMediaFiles(prev => [...prev, ...newFiles]);
    setAddedMediaFiles(prev => [...prev, ...newFiles]);
  }, []);

  const handleRemoveMedia = useCallback((index) => {
    setTempMediaFiles(prev => prev.filter((_, i) => i !== index));
    setAddedMediaFiles(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleEditToggle = useCallback(() => {
    if (isEditing) {
      setTempMediaFiles(mediaFiles);
      setEditableContent(content);
      setAddedMediaFiles([]);
      setEditableSelectedDate(selectedDate);
    }
    setIsEditing(prev => !prev);
  }, [isEditing, mediaFiles, content, selectedDate]);

  const closeEditMode = useCallback(() => {
    setIsEditing(false);
    setIsCancelDialogOpen(false);
  }, []);

  const handleCancelEdit = useCallback(() => {
    const hasChanges = 
      addedMediaFiles.length > 0 || 
      tempMediaFiles.length !== mediaFiles.length || 
      editableContent !== content ||
      editableSelectedDate !== selectedDate;

    if (hasChanges) {
      setIsCancelDialogOpen(true);
    } else {
      closeEditMode();
    }
  }, [addedMediaFiles.length, tempMediaFiles.length, mediaFiles.length, editableContent, content, editableSelectedDate, selectedDate, closeEditMode]);

  const confirmCancelEdit = useCallback(() => {
    setTempMediaFiles(mediaFiles);
    setEditableContent(content);
    setAddedMediaFiles([]);
    setEditableSelectedDate(selectedDate);
    closeEditMode();
  }, [mediaFiles, content, selectedDate, closeEditMode]);

  const handleContentChange = useCallback((newContent) => {
    setEditableContent(newContent);
  }, []);

  const handlePartialRegenerate = useCallback(async (selectedText) => {
    setIsPartialGenerating(true);
    try {
      const updatedContent = await regeneratePartialPost(editableContent, selectedText);
      if (updatedContent) {
        setEditableContent(updatedContent);
      }
    } catch (error) {
      console.error('Erreur lors de la régénération partielle:', error);
    } finally {
      setIsPartialGenerating(false);
    }
  }, [editableContent]);

  const handleSaveChanges = useCallback(async () => {
    if (!draftId || typeof draftId !== 'string') {
      console.error('draftId invalide');
      return;
    }

    try {
      const uploadedMediaUrls = await uploadMediaFiles(addedMediaFiles);
      const updatedMediaUrls = [
        ...tempMediaFiles.filter((file) => file.url),
        ...uploadedMediaUrls,
      ];

      const updateData = {
        mediaUrls: updatedMediaUrls,
        content: editableContent,
        selectedDate: editableSelectedDate,
        updatedAt: new Date(),
      };

      await updateDraftPost(draftId, updateData);

      if (onUpdate) {
        onUpdate(draftId, updateData);
      }

      setTempMediaFiles(updatedMediaUrls);
      setAddedMediaFiles([]);
      closeEditMode();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    }
  }, [draftId, addedMediaFiles, tempMediaFiles, editableContent, editableSelectedDate, onUpdate, closeEditMode]);

  const handlePlatformsChange = useCallback(async (platforms) => {
    try {
      setSelectedPlatforms(platforms);
      
      if (draftId) {
        const updateData = {
          selectedPlatforms: platforms,
          updatedAt: new Date(),
        };

        await updateDoc(doc(db, 'draftPosts', draftId), updateData);

        if (onUpdate) {
          onUpdate(draftId, updateData);
        }
      }
    } catch (error) {
      setSelectedPlatforms(prev => ({...prev}));
      console.error('Erreur lors de la mise à jour des plateformes:', error);
    }
  }, [draftId, onUpdate]);

  const handleUpdateMedia = async (draftId, updatedMediaFiles) => {
    try {
      const updateData = {
        mediaUrls: updatedMediaFiles,
        updatedAt: new Date(),
      };

      await updateDraftPost(draftId, updateData);

      if (onUpdate) {
        onUpdate(draftId, updateData);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des médias:', error);
    }
  };

  // Rendu du composant
  return (
    <>
      <EditPostModal
        open={isEditing}
        onClose={handleEditToggle}
        content={editableContent}
        mediaFiles={tempMediaFiles}
        companyName={companyName}
        companyLogoUrl={companyLogoUrl}
        timestamp={timestamp}
        onSave={handleSaveChanges}
        onCancelEdit={handleCancelEdit}
        isGenerating={isGenerating}
        isPublishing={isPublishing}
        selectedDate={editableSelectedDate}
        onAddMedia={handleAddMedia}
        onRemoveMedia={handleRemoveMedia}
        editableContent={editableContent}
        onContentChange={handleContentChange}
        onPartialRegenerate={handlePartialRegenerate}
        isPartialGenerating={isPartialGenerating}
        onDateChange={setEditableSelectedDate}
      />

      {!isEditing && (
        <Card
          sx={{
            borderRadius: 2,
            boxShadow: 1,
            backgroundColor: 'background.paper',
            maxHeight: scroll ? '80vh' : 'none',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {(isGenerating || isPublishing) && <LoadingOverlay />}
          <CardActionsTopSection
            prompt={prompt}
            isEditing={false}
            isPublished={isPublished}
            isGenerating={isGenerating}
            onDelete={onDelete}
            onEdit={handleEditToggle}
            onRegenerate={onRegenerate}
          />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
            <CompanyInfo companyName={companyName} companyLogoUrl={companyLogoUrl} timestamp={timestamp} />
            <PostContent
              content={content}
              isGenerating={isGenerating}
              isEditing={false}
            />
            <MediaPreview mediaFiles={mediaFiles} isEditing={false} />
          </CardContent>
          <CardActionsBottomSection
            isEditing={false}
            isGenerating={isGenerating}
            isPublishing={isPublishing}
            isPublished={isPublished}
            onPublishToFacebook={onPublishToFacebook}
            onSchedule={onSchedule}
            selectedDate={selectedDate}
            isMobile={isMobile}
            selectedPlatforms={selectedPlatforms}
            onPlatformsChange={handlePlatformsChange}
          />
        </Card>
      )}

      <CancelEditDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onConfirm={confirmCancelEdit}
      />
    </>
  );
}

export default PostCard;
