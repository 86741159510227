import React, { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TitleIcon from '@mui/icons-material/Title';
import { Textbox } from 'fabric';

function Text({ canvas }) {
    useEffect(() => {
        if (!canvas) return;

        // Configurer le canvas pour l'édition de texte
        canvas.on('mouse:dblclick', (e) => {
            if (e.target && e.target.type === 'textbox') {
                canvas.setActiveObject(e.target);
                e.target.enterEditing();
                e.target.selectAll();
                canvas.requestRenderAll();
            }
        });

        return () => {
            canvas.off('mouse:dblclick');
        };
    }, [canvas]);

    const createTextbox = (options) => {
        if (!canvas) return;

        const text = new Textbox(options.text, {
            left: 100,
            top: 100,
            fontSize: options.fontSize || 20,
            fontFamily: 'Arial',
            fill: '#000000',
            editable: true,
            selectable: true,
            hasControls: true,
            hasBorders: true,
            ...options
        });

        canvas.add(text);
        canvas.setActiveObject(text);
        text.enterEditing();
        text.selectAll();
        canvas.requestRenderAll();
    };

    const addText = () => {
        createTextbox({
            text: 'Double-cliquez pour éditer',
            fontSize: 20
        });
    };

    const addTitle = () => {
        createTextbox({
            text: 'Titre',
            fontSize: 36,
            fontWeight: 'bold'
        });
    };

    return (
        <>
            <Tooltip title="Ajouter du texte">
                <IconButton color="primary" onClick={addText}>
                    <TextFieldsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Ajouter un titre">
                <IconButton color="primary" onClick={addTitle}>
                    <TitleIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default Text;