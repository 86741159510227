import React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

function DeleteButton({ onClick, disabled }) {
  const theme = useTheme();
  
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        color: theme.palette.error.main,
        bgcolor: theme.palette.mode === 'dark' 
          ? 'rgba(50, 50, 50, 0.8)' 
          : 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
          bgcolor: theme.palette.mode === 'dark' 
            ? 'rgba(70, 70, 70, 1)' 
            : 'rgba(255, 255, 255, 1)',
        },
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
}

export default DeleteButton;
