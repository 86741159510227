import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CircleIcon from '@mui/icons-material/Circle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Rect, Circle, Triangle } from 'fabric';

function Shapes({ canvas }) {
    const addRectangle = () => {
        if (canvas) {
            const rectangle = new Rect({
                top: 100,
                left: 100,
                width: 100,
                height: 60,
                fill: '#D84D42',
                rx: 0,
                ry: 0,
                transparentCorners: false,
                strokeWidth: 2,
                stroke: '#000000',
                opacity: 1,
                shadow: null
            });
            canvas.add(rectangle);
            canvas.renderAll();
        }
    };

    const addCircle = () => {
        if (canvas) {
            const circle = new Circle({
                top: 100,
                left: 100,
                radius: 50,
                fill: '#23B769',
                transparentCorners: false,
                strokeWidth: 2,
                stroke: '#000000',
                opacity: 1,
                shadow: null
            });
            canvas.add(circle);
            canvas.renderAll();
        }
    };

    const addTriangle = () => {
        if (canvas) {
            const triangle = new Triangle({
                top: 100,
                left: 100,
                width: 100,
                height: 100,
                fill: '#4287f5',
                transparentCorners: false,
                strokeWidth: 2,
                stroke: '#000000',
                opacity: 1,
                shadow: null
            });
            canvas.add(triangle);
            canvas.renderAll();
        }
    };

    return (
        <>
            <Tooltip title="Ajouter un carré">
                <IconButton color="primary" onClick={addRectangle}>
                    <CropSquareIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Ajouter un cercle">
                <IconButton color="primary" onClick={addCircle}>
                    <CircleIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Ajouter un triangle">
                <IconButton color="primary" onClick={addTriangle}>
                    <ChangeHistoryIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default Shapes; 