import React, { useState } from 'react';
import { Box, Paper, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ShapesIcon from '@mui/icons-material/Category';
import MediaIcon from '@mui/icons-material/PermMedia';
import TextIcon from '@mui/icons-material/TextFields';
import LayersIcon from '@mui/icons-material/Layers';
import Shapes from './Shapes';
import Video from './Video';
import Picture from './Picture';
import Text from './Text';
import Layers from './Layers';

function SideMenu({ type, canvas, canvasRef }) {
    const [activeSection, setActiveSection] = useState('tools');

    const getTitle = () => {
        if (activeSection === 'layers') return 'Calques';
        switch(type) {
            case 'shapes': return 'Formes';
            case 'media': return 'Médias';
            case 'text': return 'Texte';
            default: return '';
        }
    };

    const renderContent = () => {
        if (activeSection === 'layers') {
            return <Layers canvas={canvas} />;
        }

        switch(type) {
            case 'shapes':
                return <Shapes canvas={canvas} />;
            case 'media':
                return (
                    <>
                        <Video canvas={canvas} canvasRef={canvasRef} />
                        <Picture canvas={canvas} />
                    </>
                );
            case 'text':
                return <Text canvas={canvas} />;
            default:
                return null;
        }
    };

    return (
        <Paper
            sx={{
                width: '250px',
                minHeight: '100%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1,
                borderRadius: '0 8px 8px 0',
                boxShadow: 2,
                overflow: 'hidden'
            }}
        >
            {/* Contenu Principal */}
            <Box sx={{ p: 2, flex: 1, overflowY: 'auto' }}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    {getTitle()}
                </Typography>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'flex-start',
                    minHeight: type ? '200px' : 'auto'
                }}>
                    {renderContent()}
                </Box>
            </Box>

            {/* Navigation du bas */}
            <BottomNavigation
                value={activeSection}
                onChange={(event, newValue) => {
                    setActiveSection(newValue);
                }}
                showLabels
                sx={{
                    borderTop: '1px solid #eee',
                }}
            >
                <BottomNavigationAction 
                    label="Outils" 
                    value="tools"
                    icon={type === 'shapes' ? <ShapesIcon /> : 
                          type === 'media' ? <MediaIcon /> : 
                          type === 'text' ? <TextIcon /> : <ShapesIcon />}
                />
                <BottomNavigationAction 
                    label="Calques" 
                    value="layers"
                    icon={<LayersIcon />} 
                />
            </BottomNavigation>
        </Paper>
    );
}

export default SideMenu; 