import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const ApexChart = ({ type, height, width, series, options, ...props }) => {
  const theme = useTheme();
  
  // Appliquer les couleurs du thème aux options du graphique
  const defaultOptions = {
    chart: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      foreColor: theme.palette.text.secondary,
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.success.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 3,
    },
    tooltip: {
      theme: theme.palette.mode,
    },
    xaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    legend: {
      labels: {
        colors: theme.palette.text.primary,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            style: {
              colors: theme.palette.text.primary,
            },
          },
        },
      },
    },
  };

  // Fusionner les options par défaut avec les options personnalisées
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    chart: {
      ...defaultOptions.chart,
      ...(options?.chart || {}),
    },
    plotOptions: {
      ...defaultOptions.plotOptions,
      ...(options?.plotOptions || {}),
    },
  };

  return (
    <ReactApexChart
      type={type || 'line'}
      height={height || 350}
      width={width || '100%'}
      series={series}
      options={mergedOptions}
      {...props}
    />
  );
};

export default ApexChart; 