import React from "react";
import Menuitems from "./MenuItems";
import { Box, List } from "@mui/material";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup";

const SidebarItems = ({ toggleMobileSidebar, isCollapsed }) => {
  return (
    <Box sx={{ px: isCollapsed ? 1 : 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav" component="div">
        {Menuitems.map((item) => {
          // Si c'est un en-tête de section et qu'on n'est pas en mode réduit
          if (item.navlabel && !isCollapsed) {
            return <NavGroup item={item} key={item.subheader} />;
          } 
          // Si c'est un élément de menu normal (ou si on est en mode réduit)
          else if (!item.navlabel) {
            return (
              <NavItem
                item={item}
                key={item.id}
                onClick={toggleMobileSidebar}
                isCollapsed={isCollapsed}
              />
            );
          }
          // Ne rien afficher pour les labels en mode réduit
          return null;
        })}
      </List>
    </Box>
  );
};

export default SidebarItems; 