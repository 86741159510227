import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function CompanyInfo({ companyName, companyLogoUrl, timestamp }) {
  const theme = useTheme();
  let formattedDate = '';

  try {
    // Vérification que timestamp est un objet et contient une propriété seconds
    if (timestamp && typeof timestamp.seconds === 'number') {
      // Conversion du timestamp Firestore en date JavaScript
      const date = new Date(timestamp.seconds * 1000);

      // Formatage de la date
      formattedDate = date.toLocaleString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    } else {
      formattedDate = 'Date non disponible';
    }
  } catch (error) {
    console.error("Erreur lors de la conversion du timestamp : ", error);
    formattedDate = 'Date non disponible';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
      <Avatar src={companyLogoUrl} alt={companyName} sx={{ width: 50, height: 50 }} />
      <Box>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
          {companyName}
        </Typography>
        <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
          {formattedDate}
        </Typography>
      </Box>
    </Box>
  );
}

export default CompanyInfo;
