import React, { createContext, useState, useContext, useEffect } from 'react';

// Création du contexte pour le thème
const ThemeContext = createContext();

// Hook personnalisé pour utiliser le contexte du thème
export const useThemeMode = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  // Récupération du mode de thème dans localStorage ou 'light' par défaut
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode || 'light';
  });

  // Fonction pour basculer entre les modes clair et sombre
  const toggleThemeMode = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newMode);
      return newMode;
    });
  };

  // Mise à jour du mode dès que la valeur change
  useEffect(() => {
    localStorage.setItem('themeMode', mode);
    
    // Ajout d'un attribut data-theme pour d'éventuels styles CSS
    document.documentElement.setAttribute('data-theme', mode);
  }, [mode]);

  // Valeur du contexte
  const contextValue = {
    mode,
    isDarkMode: mode === 'dark',
    toggleThemeMode
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext; 