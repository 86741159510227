import React from 'react';
import ApexChart from './ApexChart';
import { useTheme } from '@mui/material/styles';

const LineChart = ({ data, title, height = 350, curve = 'smooth' }) => {
  const theme = useTheme();
  
  // Transformer les données au format attendu par ApexCharts
  const categories = data.slice(1).map(item => item[0]);
  
  // Créer une série pour chaque colonne de données (à partir de la deuxième colonne)
  const series = [];
  for (let i = 1; i < data[0].length; i++) {
    series.push({
      name: data[0][i],
      data: data.slice(1).map(item => item[i]),
    });
  }
  
  const options = {
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: curve,
      width: 3,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
    },
    grid: {
      borderColor: theme.palette.divider,
      row: {
        colors: [theme.palette.background.default, 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 4,
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Période',
      },
    },
    yaxis: {
      title: {
        text: 'Valeur',
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetY: 0,
      offsetX: 0,
    },
  };

  return <ApexChart type="line" height={height} series={series} options={options} />;
};

export default LineChart; 