import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Avatar,
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  Paper,
  useTheme,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { fetchOwnerInfo, editOwnerInfo } from '../../../../../services/firebase/ownerService';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PasswordConfirmationDialog from './PasswordConfirmationDialog';

const auth = getAuth();
const storage = getStorage();

function OwnerProfile() {
  const theme = useTheme();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          setLoading(true);
          const ownerData = await fetchOwnerInfo(user.uid);
          if (ownerData) {
            setFirstName(ownerData.firstName || '');
            setLastName(ownerData.lastName || '');
            setEmail(ownerData.email || '');
            setProfilePhotoUrl(ownerData.profilePhotoUrl || null);
            setProfilePhotoPreview(ownerData.profilePhotoUrl || null);
          }
        } catch (err) {
          setError('Erreur lors du chargement des informations utilisateur.');
        } finally {
          setLoading(false);
        }
      } else {
        setError('Utilisateur non authentifié');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const uploadPhoto = async (file, userId) => {
    const photoRef = ref(storage, `profilePhotos/${userId}`);
    await uploadBytes(photoRef, file);
    return getDownloadURL(photoRef);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (profilePhotoPreview && profilePhotoPreview.startsWith('blob:')) {
        URL.revokeObjectURL(profilePhotoPreview);
      }
      setProfilePhotoPreview(URL.createObjectURL(file));
      setProfilePhotoUrl(file);
      setIsModified(true);
    }
  };

  const handleInputChange = (setter, value, initialValue) => {
    setter(value);
    if (value !== initialValue) {
      setIsModified(true);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setSuccess(false);

      const userId = auth.currentUser?.uid;
      if (!userId) {
        throw new Error('Utilisateur non authentifié');
      }

      let newPhotoUrl = profilePhotoUrl;

      if (profilePhotoUrl instanceof File) {
        newPhotoUrl = await uploadPhoto(profilePhotoUrl, userId);
      }

      const updatedData = {
        firstName,
        lastName,
        profilePhotoUrl: newPhotoUrl,
      };

      await editOwnerInfo(userId, updatedData);
      setProfilePhotoPreview(newPhotoUrl);
      setSuccess(true);
      setIsModified(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Style commun pour les TextField qui s'adapte au mode sombre
  const textFieldStyle = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f9f9f9',
    borderRadius: 1,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, px: 2 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          borderRadius: 2, 
          maxWidth: 600, 
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <IconButton component="label">
            <Avatar
              src={profilePhotoPreview}
              sx={{ width: 100, height: 100, mr: 2, boxShadow: 3, border: '3px solid #1976d2' }}
            >
              <CameraAltIcon fontSize="large" />
            </Avatar>
            <input type="file" hidden accept="image/*" onChange={handlePhotoChange} />
          </IconButton>
          <Box>
            <Typography variant="h6" fontWeight={600}>
              Informations personnelles
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Personnalisez vos informations personnelles.
            </Typography>
          </Box>
        </Box>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>Informations mises à jour avec succès.</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Prénom"
              fullWidth
              value={firstName}
              onChange={(e) => handleInputChange(setFirstName, e.target.value, firstName)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nom"
              fullWidth
              value={lastName}
              onChange={(e) => handleInputChange(setLastName, e.target.value, lastName)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              value={email}
              disabled
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setIsPasswordDialogOpen(true)}
            >
              Modifier le mot de passe
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 2, textTransform: 'none' }}
            disabled={!isModified}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
            onClick={handleSave}
            disabled={!isModified}
          >
            Enregistrer
          </Button>
        </Box>
      </Paper>

      <PasswordConfirmationDialog
        open={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
        onConfirm={() => {
          setIsPasswordDialogOpen(false);
          setSuccess(true);
        }}
      />
    </Box>
  );
}

export default OwnerProfile;
