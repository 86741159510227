import React, { useState } from 'react';
import { Grid, Box, Button, Typography, Select, MenuItem, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PieChart from '../../../../components/Charts/PieChart';
import BarChart from '../../../../components/Charts/BarChart';
import LineChart from '../../../../components/Charts/LineChart';
import ChartCard from '../../../../components/Charts/ChartCard';
import RefreshIcon from '@mui/icons-material/Refresh';

function HomeAppPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [period, setPeriod] = useState('month');

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const pieChartData = [
    ['Content Type', 'Percentage'],
    ['Images', 40],
    ['Videos', 30],
    ['Text Posts', 20],
    ['Stories', 10],
  ];

  const barChartData = [
    ['Platform', 'Followers'],
    ['Facebook', 5000],
    ['Instagram', 12000],
    ['Twitter', 8000],
    ['LinkedIn', 4000],
  ];

  const lineChartData = [
    ['Month', 'Likes', 'Shares'],
    ['Jan', 1000, 400],
    ['Feb', 1170, 460],
    ['Mar', 660, 1120],
    ['Apr', 1030, 540],
    ['May', 1200, 600],
    ['Jun', 1300, 800],
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3, maxWidth: 1400, margin: '0 auto' }}>
      {/* Welcome Section */}
      <Box sx={{ marginBottom: 3, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Bienvenue sur votre tableau de bord des réseaux sociaux
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Analysez vos données et optimisez vos performances sur les réseaux sociaux.
        </Typography>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <ChartCard 
            title="Répartition des types de contenu"
            action={
              <IconButton size="small" color="primary">
                <RefreshIcon />
              </IconButton>
            }
          >
            <PieChart 
              data={pieChartData} 
              height={300} 
              donut={true}
            />
          </ChartCard>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} md={6}>
          <ChartCard 
            title="Abonnés par plateforme"
            subtitle="Comparaison du nombre d'abonnés sur les différentes plateformes"
            action={
              <IconButton size="small" color="primary">
                <RefreshIcon />
              </IconButton>
            }
          >
            <BarChart 
              data={barChartData} 
              height={300}
            />
          </ChartCard>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12}>
          <ChartCard 
            title="Engagement au fil du temps"
            action={
              <Select
                value={period}
                onChange={handlePeriodChange}
                size="small"
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="week">Semaine</MenuItem>
                <MenuItem value="month">Mois</MenuItem>
                <MenuItem value="year">Année</MenuItem>
              </Select>
            }
          >
            <LineChart 
              data={lineChartData} 
              height={300}
              curve="smooth"
            />
          </ChartCard>
        </Grid>

        {/* Navigation Buttons */}
        <Grid item xs={12}>
          <ChartCard>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/dashboard/new-post')}
              >
                Créer une nouvelle publication
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate('/dashboard/calendar')}
              >
                Voir le calendrier
              </Button>
            </Box>
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeAppPage;
