import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Divider,
  Alert,
  Chip,
  Switch,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

// Icônes pour la démonstration
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Favorite as FavoriteIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';

const ThemeTestPage = () => {
  return (
    <Box className="theme-test-page" sx={{ 
      width: '100%', 
      height: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      bgcolor: 'background.default'
    }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Test du Thème Modernize
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Cette page présente les composants UI avec le nouveau thème.
        </Typography>
        
        <Divider sx={{ my: 4 }} />
        
        {/* Typography */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Typographie</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h1" gutterBottom>H1 - Titre principal</Typography>
                <Typography variant="h2" gutterBottom>H2 - Sous-titre</Typography>
                <Typography variant="h3" gutterBottom>H3 - Titre de section</Typography>
                <Typography variant="h4" gutterBottom>H4 - Sous-section</Typography>
                <Typography variant="h5" gutterBottom>H5 - Titre de paragraphe</Typography>
                <Typography variant="h6" gutterBottom>H6 - Petit titre</Typography>
                <Typography variant="body1" gutterBottom>
                  Body1 - Texte standard pour le contenu principal. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Body2 - Texte plus petit pour le contenu secondaire. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Subtitle1 - Utilisé pour les sous-titres importants.
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Subtitle2 - Utilisé pour les sous-titres moins importants.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        
        {/* Buttons */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Boutons</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
                  <Button variant="contained" color="primary">Primaire</Button>
                  <Button variant="contained" color="secondary">Secondaire</Button>
                  <Button variant="contained" color="success">Succès</Button>
                  <Button variant="contained" color="error">Erreur</Button>
                  <Button variant="contained" color="info">Info</Button>
                  <Button variant="contained" color="warning">Avertissement</Button>
                </Box>
                
                <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
                  <Button variant="outlined" color="primary">Primaire</Button>
                  <Button variant="outlined" color="secondary">Secondaire</Button>
                  <Button variant="outlined" color="success">Succès</Button>
                  <Button variant="outlined" color="error">Erreur</Button>
                  <Button variant="outlined" color="info">Info</Button>
                  <Button variant="outlined" color="warning">Avertissement</Button>
                </Box>
                
                <Box display="flex" flexWrap="wrap" gap={2}>
                  <Button variant="text" color="primary">Primaire</Button>
                  <Button variant="text" color="secondary">Secondaire</Button>
                  <Button variant="text" color="success">Succès</Button>
                  <Button variant="text" color="error">Erreur</Button>
                  <Button variant="text" color="info">Info</Button>
                  <Button variant="text" color="warning">Avertissement</Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        
        {/* Cards */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Cartes</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom>
                    Carte standard
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ceci est une carte standard avec du contenu de base.
                    Elle utilise le style par défaut du thème Modernize.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card sx={{ backgroundColor: 'primary.light' }}>
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom>
                    Carte colorée
                  </Typography>
                  <Typography variant="body2">
                    Carte avec une couleur de fond personnalisée, utilisant la palette du thème.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained" color="primary">Action</Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" component="div" gutterBottom>
                    Carte avec contour
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Cette carte utilise un style avec contour au lieu d'une ombre.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="secondary">Action</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
        
        {/* Form Components */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Composants de formulaire</Typography>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Champ texte standard"
                  variant="outlined"
                  placeholder="Entrez du texte"
                  helperText="Exemple de texte d'aide"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Champ avec erreur"
                  variant="outlined"
                  error
                  helperText="Erreur: Ce champ est obligatoire"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Champ désactivé"
                  variant="outlined"
                  disabled
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>Interrupteurs</Typography>
                    <Box display="flex" alignItems="center">
                      <Switch defaultChecked />
                      <Switch />
                      <Switch disabled defaultChecked />
                      <Switch disabled />
                    </Box>
                  </Box>
                  
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>Puces</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      <Chip label="Standard" />
                      <Chip label="Primaire" color="primary" />
                      <Chip label="Secondaire" color="secondary" />
                      <Chip label="Succès" color="success" />
                      <Chip label="Avec icône" icon={<FavoriteIcon />} color="error" />
                      <Chip label="Cliquable" onClick={() => alert('Chip clicked')} color="info" />
                      <Chip label="Supprimable" onDelete={() => {}} color="warning" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        
        {/* Alerts and Notifications */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Alertes</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="success" sx={{ mb: 2 }}>Ceci est une alerte de succès — vérifiez-la!</Alert>
              <Alert severity="info" sx={{ mb: 2 }}>Ceci est une alerte d'information — vérifiez-la!</Alert>
              <Alert severity="warning" sx={{ mb: 2 }}>Ceci est une alerte d'avertissement — vérifiez-la!</Alert>
              <Alert severity="error" sx={{ mb: 2 }}>Ceci est une alerte d'erreur — vérifiez-la!</Alert>
            </Grid>
          </Grid>
        </Box>
        
        {/* Lists */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Listes</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <HomeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Accueil" secondary="Page principale" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <FavoriteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Favoris" secondary="Vos éléments préférés" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <SettingsIcon color="info" />
                    </ListItemIcon>
                    <ListItemText primary="Paramètres" secondary="Configuration de l'application" />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper>
                <List>
                  <ListItem secondaryAction={
                    <Box>
                      <IconButton edge="end" aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }>
                    <ListItemText primary="Élément avec actions" secondary="Avec boutons d'action" />
                  </ListItem>
                  <Divider />
                  <ListItem secondaryAction={
                    <Chip label="Nouveau" color="success" size="small" />
                  }>
                    <ListItemText primary="Élément avec badge" secondary="Avec indication de statut" />
                  </ListItem>
                  <Divider />
                  <ListItem secondaryAction={
                    <Switch defaultChecked />
                  }>
                    <ListItemText primary="Élément avec interrupteur" secondary="Activez ou désactivez" />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        
        {/* Tables */}
        <Box mb={6}>
          <Typography variant="h3" gutterBottom>Tableau</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Rôle</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>John Doe</TableCell>
                  <TableCell>john@example.com</TableCell>
                  <TableCell>Administrateur</TableCell>
                  <TableCell><Chip label="Actif" color="success" size="small" /></TableCell>
                  <TableCell>
                    <IconButton size="small"><EditIcon fontSize="small" /></IconButton>
                    <IconButton size="small"><DeleteIcon fontSize="small" /></IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jane Smith</TableCell>
                  <TableCell>jane@example.com</TableCell>
                  <TableCell>Éditeur</TableCell>
                  <TableCell><Chip label="En attente" color="warning" size="small" /></TableCell>
                  <TableCell>
                    <IconButton size="small"><EditIcon fontSize="small" /></IconButton>
                    <IconButton size="small"><DeleteIcon fontSize="small" /></IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Robert Johnson</TableCell>
                  <TableCell>robert@example.com</TableCell>
                  <TableCell>Utilisateur</TableCell>
                  <TableCell><Chip label="Inactif" color="error" size="small" /></TableCell>
                  <TableCell>
                    <IconButton size="small"><EditIcon fontSize="small" /></IconButton>
                    <IconButton size="small"><DeleteIcon fontSize="small" /></IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default ThemeTestPage; 