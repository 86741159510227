import React, { useState, useRef } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  InputLabel,
  FormControl
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { Image as FabricImage } from 'fabric';

const CANVAS_PRESETS = [
  { name: 'Business Card', width: 525, height: 300 },
  { name: 'Slide 16:9', width: 720, height: 405 },
  { name: 'LinkedIn Carousel', width: 1080, height: 1080 },
  { name: 'A4', width: 1241, height: 1754 },
  { name: 'Instagram', width: 540, height: 540 },
  { name: 'Facebook', width: 820, height: 312 },
  { name: 'LinkedIn', width: 1128, height: 191 },
  { name: 'Twitter', width: 1500, height: 500 },
  { name: 'Google', width: 336, height: 280 }
];

const CanvasSettings = ({ canvas }) => {
  const [width, setWidth] = useState(canvas ? canvas.width : 800);
  const [height, setHeight] = useState(canvas ? canvas.height : 600);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const fileInputRef = useRef(null);

  const handlePresetChange = (event) => {
    const preset = CANVAS_PRESETS[event.target.value];
    setWidth(preset.width);
    setHeight(preset.height);
    updateCanvasSize(preset.width, preset.height);
  };

  const handleWidthChange = (event) => {
    const newWidth = parseInt(event.target.value, 10);
    if (!isNaN(newWidth) && newWidth > 0) {
      setWidth(newWidth);
      updateCanvasSize(newWidth, height);
    }
  };

  const handleHeightChange = (event) => {
    const newHeight = parseInt(event.target.value, 10);
    if (!isNaN(newHeight) && newHeight > 0) {
      setHeight(newHeight);
      updateCanvasSize(width, newHeight);
    }
  };

  const updateCanvasSize = (newWidth, newHeight) => {
    if (!canvas) return;

    // Calculer le facteur d'échelle pour les objets
    const scaleX = newWidth / canvas.width;
    const scaleY = newHeight / canvas.height;

    // Mettre à jour la taille du canvas
    canvas.setWidth(newWidth);
    canvas.setHeight(newHeight);

    // Mettre à l'échelle et repositionner tous les objets
    canvas.getObjects().forEach(obj => {
      obj.scaleX *= scaleX;
      obj.scaleY *= scaleY;
      obj.left *= scaleX;
      obj.top *= scaleY;
      obj.setCoords();
    });

    canvas.renderAll();
  };

  const handleBackgroundColorChange = (event) => {
    const newColor = event.target.value;
    setBackgroundColor(newColor);
    if (canvas) {
      canvas.backgroundColor = newColor;
      canvas.renderAll();
    }
  };

  const handleBackgroundImageClick = () => {
    fileInputRef.current.click();
  };

  const handleBackgroundImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && canvas) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgElement = new Image();
        imgElement.src = e.target.result;
        imgElement.onload = () => {
          const fabricImage = new FabricImage(imgElement, {
            crossOrigin: 'anonymous'
          });
          
          // Calculer le ratio pour couvrir tout le canvas
          const scaleX = canvas.width / fabricImage.width;
          const scaleY = canvas.height / fabricImage.height;
          
          canvas.setBackgroundColor(null, canvas.renderAll.bind(canvas));
          canvas.backgroundImage = fabricImage;
          canvas.backgroundImage.scaleX = scaleX;
          canvas.backgroundImage.scaleY = scaleY;
          canvas.renderAll();
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6" gutterBottom>
        Paramètres du Canvas
      </Typography>

      <FormControl fullWidth>
        <InputLabel>Format prédéfini</InputLabel>
        <Select
          label="Format prédéfini"
          onChange={handlePresetChange}
          defaultValue=""
        >
          {CANVAS_PRESETS.map((preset, index) => (
            <MenuItem key={preset.name} value={index}>
              {preset.name} ({preset.width}x{preset.height})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          label="Largeur"
          type="number"
          value={width}
          onChange={handleWidthChange}
          fullWidth
          size="small"
        />
        <TextField
          label="Hauteur"
          type="number"
          value={height}
          onChange={handleHeightChange}
          fullWidth
          size="small"
        />
      </Box>

      <TextField
        label="Couleur de fond"
        type="color"
        value={backgroundColor}
        onChange={handleBackgroundColorChange}
        fullWidth
        sx={{
          '& input': {
            height: 50,
            cursor: 'pointer'
          }
        }}
      />

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleBackgroundImageUpload}
      />

      <Button
        variant="outlined"
        startIcon={<ImageIcon />}
        onClick={handleBackgroundImageClick}
        fullWidth
      >
        Image de fond
      </Button>
    </Box>
  );
};

export default CanvasSettings; 