import React, { useEffect, useState } from 'react';
import { Point, ActiveSelection, Circle, Rect, Textbox } from 'fabric';
import ContextMenuCanvas from './ContextMenuCanvas';

const CanvasManager = ({ canvas, canvasRef }) => {
  const [selectedObject, setSelectedObject] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  useEffect(() => {
    if (!canvas) return;

    // Configuration du canvas
    canvas.on('mouse:down', function(opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });

    canvas.on('mouse:move', function(opt) {
      if (this.isDragging) {
        const evt = opt.e;
        this.relativePan(new Point(
          evt.clientX - this.lastPosX,
          evt.clientY - this.lastPosY
        ));
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });

    canvas.on('mouse:up', function(opt) {
      this.isDragging = false;
      this.selection = true;
    });

    // Double-clic pour éditer le texte
    canvas.on('mouse:dblclick', function(opt) {
      if (opt.target && opt.target.type === 'textbox') {
        opt.target.enterEditing();
        this.requestRenderAll();
      }
    });

    // Gestion du clic droit
    canvas.upperCanvasEl.addEventListener('contextmenu', function(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      
      const target = canvas.findTarget(evt);
      if (target) {
        canvas.setActiveObject(target);
        setSelectedObject(target);
        setContextMenu({
          mouseX: evt.pageX,
          mouseY: evt.pageY
        });
      }
    });

    // Gestion des raccourcis clavier
    const handleKeyDown = (e) => {
      if (!e.metaKey && !e.ctrlKey) return;

      const activeObject = canvas.getActiveObject();
      
      switch (e.key.toLowerCase()) {
        case 'c': // Copier
          e.preventDefault();
          if (activeObject) handleCopy();
          break;
        case 'v': // Coller
          e.preventDefault();
          handlePaste();
          break;
        case 'x': // Couper
          e.preventDefault();
          if (activeObject) handleCut();
          break;
        case 'd': // Dupliquer
          e.preventDefault();
          if (activeObject) handleDuplicate();
          break;
        case 'a': // Tout sélectionner
          e.preventDefault();
          handleSelectAll();
          break;
        case '[': // Envoyer en arrière
          e.preventDefault();
          if (activeObject) handleSendToBack();
          break;
        case ']': // Mettre en avant
          e.preventDefault();
          if (activeObject) handleBringToFront();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      canvas.off('mouse:down');
      canvas.off('mouse:move');
      canvas.off('mouse:up');
      canvas.off('mouse:dblclick');
      canvas.upperCanvasEl.removeEventListener('contextmenu');
    };
  }, [canvas]);

  const handleContextMenuClose = () => {
    setContextMenu(null);
    setSelectedObject(null);
  };

  const handleCopy = () => {
    if (!selectedObject) return;
    try {
      const objectData = selectedObject.toObject();
      canvas._clipboard = {
        type: selectedObject.type,
        data: objectData
      };
    } catch (error) {
      console.error('Erreur lors de la copie:', error);
    }
  };

  const handlePaste = () => {
    if (!canvas._clipboard) return;
    try {
      const { type, data } = canvas._clipboard;
      let newObject;

      switch (type) {
        case 'rect':
          newObject = new Rect(data);
          break;
        case 'circle':
          newObject = new Circle(data);
          break;
        case 'textbox':
          newObject = new Textbox(data.text, data);
          break;
        default:
          return;
      }

      newObject.set({
        left: data.left + 10,
        top: data.top + 10,
        evented: true,
        selectable: true
      });

      canvas.add(newObject);
      canvas.setActiveObject(newObject);
      canvas.requestRenderAll();
    } catch (error) {
      console.error('Erreur lors du collage:', error);
    }
  };

  const handleDuplicate = () => {
    if (!selectedObject) return;
    try {
      const objectData = selectedObject.toObject();
      const type = selectedObject.type;
      let newObject;

      switch (type) {
        case 'rect':
          newObject = new Rect(objectData);
          break;
        case 'circle':
          newObject = new Circle(objectData);
          break;
        case 'textbox':
          newObject = new Textbox(objectData.text, objectData);
          break;
        default:
          return;
      }

      newObject.set({
        left: objectData.left + 10,
        top: objectData.top + 10,
        evented: true,
        selectable: true
      });

      canvas.add(newObject);
      canvas.setActiveObject(newObject);
      canvas.requestRenderAll();
    } catch (error) {
      console.error('Erreur lors de la duplication:', error);
    }
  };

  const handleCut = () => {
    if (selectedObject) {
      canvas.remove(selectedObject);
      canvas._clipboard = selectedObject;
      handleContextMenuClose();
    }
  };

  const handleDelete = () => {
    if (selectedObject) {
      canvas.remove(selectedObject);
      handleContextMenuClose();
    }
  };

  const handleBringToFront = () => {
    if (selectedObject) {
      const objects = canvas.getObjects();
      const currentIndex = objects.indexOf(selectedObject);
      const targetIndex = currentIndex + 1;
      
      if (targetIndex < objects.length) {
        canvas.remove(selectedObject);
        objects.splice(targetIndex, 0, selectedObject);
        canvas.add(selectedObject);
        canvas.setActiveObject(selectedObject);
        canvas.fire('object:modified', { target: selectedObject });
        canvas.requestRenderAll();
      }
      handleContextMenuClose();
    }
  };

  const handleSendToBack = () => {
    if (selectedObject) {
      const objects = canvas.getObjects();
      const currentIndex = objects.indexOf(selectedObject);
      
      if (currentIndex > 0) {
        // Retirer l'objet du canvas
        canvas.remove(selectedObject);
        // Réorganiser les objets
        objects.splice(currentIndex, 1);
        objects.unshift(selectedObject);
        // Réajouter tous les objets dans le bon ordre
        canvas.clear();
        objects.forEach(obj => canvas.add(obj));
        canvas.setActiveObject(selectedObject);
        canvas.fire('object:modified', { target: selectedObject });
        canvas.requestRenderAll();
      }
      handleContextMenuClose();
    }
  };

  const handleSelectAll = () => {
    const sel = new ActiveSelection(canvas.getObjects(), {
      canvas: canvas,
    });
    canvas.setActiveObject(sel);
    canvas.requestRenderAll();
    handleContextMenuClose();
  };

  return (
    <ContextMenuCanvas
      contextMenu={contextMenu}
      onClose={handleContextMenuClose}
      onCopy={handleCopy}
      onCut={handleCut}
      onDelete={handleDelete}
      onBringToFront={handleBringToFront}
      onSendToBack={handleSendToBack}
      onSelectAll={handleSelectAll}
      onDuplicate={handleDuplicate}
    />
  );
};

export default CanvasManager; 