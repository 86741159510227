import React from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';

const ChartCard = ({ title, subtitle, children, action, footer, sx = {} }) => {
  const theme = useTheme();
  
  return (
    <Card 
      sx={{ 
        boxShadow: theme.shadows[4],
        borderRadius: 2,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...sx
      }}
    >
      <CardContent sx={{ p: 3, flexGrow: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={action ? 2 : 0}>
          <Box>
            {title && (
              <Typography variant="h6" fontWeight={600} color="textPrimary">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Box>
          {action && <Box>{action}</Box>}
        </Box>
      </CardContent>
      
      <Box sx={{ flexGrow: 1, px: 3, pb: footer ? 0 : 3 }}>
        {children}
      </Box>
      
      {footer && (
        <Box sx={{ p: 3, pt: 0 }}>
          {footer}
        </Box>
      )}
    </Card>
  );
};

export default ChartCard; 