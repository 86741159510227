import React, { useState, useRef } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import { IconButton, Tooltip } from '@mui/material';
import { Canvas, FabricImage } from 'fabric';
import DeleteIcon from '@mui/icons-material/Delete';
import CropIcon from '@mui/icons-material/Crop';

function Picture({ canvas }) {
    const [imageSrc, setImageSrc] = useState(null);
    const [fabricImage, setFabricImage] = useState(null);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [uploadMessage, setUploadMessage] = useState('');
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoadedPercentage(0);
            setImageSrc(null);
            setUploadMessage('');

            const reader = new FileReader();
            
            reader.onprogress = (e) => {
                if (e.lengthComputable) {
                    const percentage = (e.loaded / e.total) * 100;
                    setLoadedPercentage(percentage);
                }
            };

            reader.onload = (e) => {
                const dataUrl = e.target.result;
                setImageSrc(dataUrl);

                FabricImage.fromURL(dataUrl).then(img => {
                    // Redimensionner l'image pour qu'elle rentre dans le canvas
                    const canvasWidth = canvas.width;
                    const canvasHeight = canvas.height;
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    const scale = Math.min(
                        canvasWidth / imgWidth,
                        canvasHeight / imgHeight,
                        1 // Ne pas agrandir si l'image est plus petite
                    );

                    img.scale(scale);
                    
                    // Centrer l'image dans le canvas
                    img.set({
                        left: (canvasWidth - imgWidth * scale) / 2,
                        top: (canvasHeight - imgHeight * scale) / 2
                    });

                    setFabricImage(img);
                    canvas.add(img);
                    canvas.renderAll();

                    setUploadMessage('Image ajoutée avec succès');
                    setTimeout(() => setUploadMessage(''), 3000);
                }).catch(error => {
                    console.error('Erreur lors du chargement de l\'image:', error);
                    setUploadMessage('Erreur lors du chargement de l\'image');
                });
            };

            reader.onerror = () => {
                setUploadMessage('Erreur lors du chargement de l\'image');
            };

            reader.readAsDataURL(file);
        }
    };

    const handleImageUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = () => {
        if (fabricImage) {
            canvas.remove(fabricImage);
            canvas.renderAll();
            setFabricImage(null);
            setImageSrc(null);
        }
    };

    const handleCropImage = () => {
        if (fabricImage) {
            // Activer le mode de recadrage
            fabricImage.set({
                cropX: 0,
                cropY: 0,
                width: fabricImage.width,
                height: fabricImage.height
            });
            canvas.setActiveObject(fabricImage);
            canvas.renderAll();
        }
    };

    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleImageUpload}
            />

            <div className="image-tools">
                <Tooltip title="Ajouter une image">
                    <IconButton color="primary" onClick={handleImageUploadButtonClick}>
                        <ImageIcon />
                    </IconButton>
                </Tooltip>

                {fabricImage && (
                    <>
                        <Tooltip title="Supprimer l'image">
                            <IconButton color="error" onClick={handleDeleteImage}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Recadrer l'image">
                            <IconButton color="primary" onClick={handleCropImage}>
                                <CropIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>

            {uploadMessage && (
                <div className="upload-message" style={{ marginTop: '10px' }}>
                    {uploadMessage}
                </div>
            )}

            {loadedPercentage > 0 && loadedPercentage < 100 && (
                <div className="progress-bar" style={{ marginTop: '10px' }}>
                    <div
                        className="progress"
                        style={{ width: `${loadedPercentage}%` }}
                    />
                </div>
            )}
        </>
    );
}

export default Picture; 