import { createTheme } from '@mui/material/styles';

// Thème inspiré de Modernize-Nextjs-Free
// Adapté pour une application React standard avec mode sombre

// Palettes de couleurs pour le mode clair et sombre
const lightPalette = {
  primary: {
    main: "#5D87FF",
    light: "#ECF2FF",
    dark: "#4570EA",
  },
  secondary: {
    main: "#49BEFF",
    light: "#E8F7FF",
    dark: "#23afdb",
  },
  success: {
    main: "#13DEB9",
    light: "#E6FFFA",
    dark: "#02b3a9",
    contrastText: "#ffffff",
  },
  info: {
    main: "#539BFF",
    light: "#EBF3FE",
    dark: "#1682d4",
    contrastText: "#ffffff",
  },
  error: {
    main: "#FA896B",
    light: "#FDEDE8",
    dark: "#f3704d",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#FFAE1F",
    light: "#FEF5E5",
    dark: "#ae8e59",
    contrastText: "#ffffff",
  },
  grey: {
    100: "#F2F6FA",
    200: "#EAEFF4",
    300: "#DFE5EF",
    400: "#7C8FAC",
    500: "#5A6A85",
    600: "#2A3547",
  },
  text: {
    primary: "#2A3547",
    secondary: "#5A6A85",
  },
  background: {
    default: "#FAFBFB",
    paper: "#FFFFFF",
  },
  divider: "#e5eaef",
};

// Palette pour le mode sombre
const darkPalette = {
  primary: {
    main: "#5D87FF",
    light: "#253662",
    dark: "#4570EA",
  },
  secondary: {
    main: "#49BEFF",
    light: "#1e464e",
    dark: "#23afdb",
  },
  success: {
    main: "#13DEB9",
    light: "#1a3d36",
    dark: "#02b3a9",
    contrastText: "#ffffff",
  },
  info: {
    main: "#539BFF",
    light: "#223a55",
    dark: "#1682d4",
    contrastText: "#ffffff",
  },
  error: {
    main: "#FA896B",
    light: "#452c25",
    dark: "#f3704d",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#FFAE1F",
    light: "#473620",
    dark: "#ae8e59",
    contrastText: "#ffffff",
  },
  grey: {
    100: "#2A3547",
    200: "#202A39",
    300: "#18202C",
    400: "#939DB5",
    500: "#B4BDD4",
    600: "#DFE5EF",
  },
  text: {
    primary: "#DFE5EF",
    secondary: "#B4BDD4",
  },
  background: {
    default: "#1A2233",
    paper: "#202A39",
  },
  divider: "#2A3547",
};

// Fonction pour créer un thème en fonction du mode (clair ou sombre)
const createAppTheme = (mode) => {
  return createTheme({
    direction: "ltr",
    palette: {
      mode,
      ...(mode === 'light' ? lightPalette : darkPalette)
    },
    typography: {
      fontFamily: '"Roboto", "Plus Jakarta Sans", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: 600,
        fontSize: "2.25rem",
        lineHeight: "2.75rem",
      },
      h2: {
        fontWeight: 600,
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
      },
      h3: {
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
      },
      h4: {
        fontWeight: 600,
        fontSize: "1.3125rem",
        lineHeight: "1.6rem",
      },
      h5: {
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: "1.6rem",
      },
      h6: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "1.2rem",
      },
      button: {
        textTransform: "capitalize",
        fontWeight: 400,
      },
      body1: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.334rem",
      },
      body2: {
        fontSize: "0.75rem",
        letterSpacing: "0rem",
        fontWeight: 400,
        lineHeight: "1rem",
      },
      subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "html, body": {
            height: "100%",
            margin: 0,
            padding: 0,
          },
          "body": {
            overflowY: "auto",
            overflowX: "hidden",
          },
          "#root": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
          ".MuiPaper-elevation9, .MuiPopover-root .MuiPaper-elevation": {
            boxShadow:
              "rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "7px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            boxShadow: "none",
            padding: "8px 20px",
            borderRadius: "5px",
            "&:hover": {
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            },
          },
          containedPrimary: {
            backgroundColor: "#5D87FF",
            "&:hover": {
              backgroundColor: "#4570EA",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? "#ffffff" : "#202A39",
            color: mode === 'light' ? "#2A3547" : "#DFE5EF",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: mode === 'light' ? "#ffffff" : "#202A39",
            color: mode === 'light' ? "#2A3547" : "#DFE5EF",
            border: "none",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: mode === 'light' ? "#e5eaef" : "#2A3547",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: mode === 'light' ? "#e5eaef" : "#2A3547",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#5D87FF",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#5D87FF",
            },
            borderRadius: "5px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${mode === 'light' ? "#e5eaef" : "#2A3547"}`,
            padding: "16px",
          },
          head: {
            fontWeight: 600,
            backgroundColor: mode === 'light' ? "#F2F6FA" : "#18202C",
          },
        },
      },
    },
  });
};

// Créer le thème clair par défaut
const modernizeTheme = createAppTheme('light');

export { createAppTheme };
export default modernizeTheme; 