import React from 'react';
import { Modal, Box, IconButton, Typography, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FabricEditor from './FabricEditor';

const ImageEditorModal = ({ open, onClose, imageUrl, onSave }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0, // Évite les marges autour
        margin: 0,
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute', // Permet d'éviter tout espace parasite
          top: 0,
          left: 0,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            bgcolor: 'rgba(0,0,0,0.05)',
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Éditeur d'image
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{
              bgcolor: 'rgba(0,0,0,0.1)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.2)' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Contenu principal */}
        <Box 
          sx={{ 
            width: '100vw',
          height: '100vh',
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <FabricEditor imageUrl={imageUrl} onSave={onSave} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageEditorModal;
