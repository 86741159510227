import React from 'react';
import { Box, Typography, Avatar, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; 
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useThemeMode } from '../../contexts/ThemeContext';

const Logo = ({ isCollapsed, toggleCollapse }) => {
  // Utiliser useThemeMode avec une valeur par défaut si le contexte n'est pas disponible
  const themeContext = useThemeMode() || { isDarkMode: false, toggleThemeMode: () => {} };
  const { isDarkMode, toggleThemeMode } = themeContext;

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: isCollapsed ? 'column' : 'row',
      alignItems: 'center', 
      justifyContent: isCollapsed ? 'center' : 'space-between',
      p: isCollapsed ? 1 : 2,
      borderBottom: '1px solid',
      borderColor: 'divider',
      overflow: 'hidden'
    }}>
      {/* Logo avec le texte "Visiblia" */}
      <Link to="/dashboard/home" style={{ 
        textDecoration: 'none', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: isCollapsed ? 'center' : 'flex-start',
        width: isCollapsed ? '100%' : 'auto'
      }}>
        {isCollapsed ? (
          // Version réduite : juste un avatar/logo
          <Avatar 
            sx={{ 
              bgcolor: 'primary.main',
              width: 36,
              height: 36,
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
            }}
          >
            V
          </Avatar>
        ) : (
          // Version complète : texte + éventuellement logo
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: 'primary.main',
                width: 32,
                height: 32,
                mr: 1,
                fontWeight: 'bold'
              }}
            >
              V
            </Avatar>
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                color: 'primary.main', 
                fontWeight: 'bold',
                letterSpacing: '0.5px'
              }}
            >
              Visiblia
            </Typography>
          </Box>
        )}
      </Link>

      {/* Si en mode réduit, le bouton d'agrandissement va sous le logo */}
      {isCollapsed && (
        <Tooltip title="Agrandir" placement="right">
          <IconButton 
            onClick={toggleCollapse}
            size="small"
            color="primary"
            sx={{ 
              p: 0.75,
              mt: 1.5,
              boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
            }}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {/* En mode normal, boutons pour le mode sombre et pour réduire le menu */}
      {!isCollapsed && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {/* Bouton pour le mode sombre/clair */}
          <Tooltip title={isDarkMode ? "Mode clair" : "Mode sombre"} placement="bottom">
            <IconButton 
              onClick={toggleThemeMode}
              size="small"
              color={isDarkMode ? "warning" : "inherit"}
              sx={{ 
                p: 0.75,
                boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
              }}
            >
              {isDarkMode ? <LightModeIcon fontSize="small" /> : <DarkModeIcon fontSize="small" />}
            </IconButton>
          </Tooltip>

          {/* Bouton pour réduire le menu */}
          <Tooltip title="Réduire" placement="bottom">
            <IconButton 
              onClick={toggleCollapse}
              size="small"
              color="primary"
              sx={{ 
                p: 0.75,
                boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
              }}
            >
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Logo; 