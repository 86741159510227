// PostContent.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function PostContent({ content, isGenerating, isEditing, onContentChange, onPartialRegenerate }) {
  const [selectedText, setSelectedText] = useState('');
  const [buttonPosition, setButtonPosition] = useState(null);
  const [isPartialGenerating, setIsPartialGenerating] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const handleMouseUp = (event) => {
      if (!isEditing) return;

      const selection = window.getSelection();
      if (selection && selection.toString().trim().length > 0) {
        const selectedText = selection.toString().trim();
        setSelectedText(selectedText);

        setButtonPosition({
          x: event.clientX + window.scrollX - 20,
          y: event.clientY + window.scrollY + 10,
        });
      } else {
        setSelectedText('');
        setButtonPosition(null);
      }
    };

    if (isEditing) {
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mouseup', handleMouseUp);
      setSelectedText('');
      setButtonPosition(null);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isEditing]);

  const handleRegenerateClick = async () => {
    if (onPartialRegenerate && selectedText) {
      setIsPartialGenerating(true);
      try {
        const updatedContent = await onPartialRegenerate(selectedText);
        if (updatedContent) {
          onContentChange(updatedContent);
        }
      } catch (error) {
        console.error('Erreur lors de la régénération partielle :', error);
      } finally {
        setIsPartialGenerating(false);
      }
    }
    setSelectedText('');
    setButtonPosition(null);
  };

  const handleContentChange = (event) => {
    onContentChange(event.target.value);
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {isEditing ? (
        <>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            value={content}
            onChange={handleContentChange}
            sx={{ 
              backgroundColor: theme.palette.background.default,
              borderRadius: 2,
              '.MuiOutlinedInput-root': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }
            }}
            disabled={isPartialGenerating}
          />
          {isPartialGenerating && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2000,
                backgroundColor: theme.palette.mode === 'dark' 
                  ? 'rgba(0, 0, 0, 0.7)' 
                  : 'rgba(255, 255, 255, 0.7)',
                borderRadius: '8px',
                padding: '10px',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      ) : (
        <Typography 
          variant="body1" 
          sx={{ 
            color: theme.palette.text.primary,
            whiteSpace: 'pre-line'
          }}
        >
          {isGenerating ? "Génération en cours..." : content}
        </Typography>
      )}
      {isEditing && selectedText && buttonPosition && !isPartialGenerating && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleRegenerateClick}
          style={{
            position: 'fixed',
            top: `${buttonPosition.y}px`,
            left: `${buttonPosition.x}px`,
            zIndex: 2000,
            backgroundColor: theme.palette.primary.main,
            transform: 'translate(-50%, 0)',
          }}
        >
          Régénérer
        </Button>
      )}
    </Box>
  );
}

export default PostContent;
