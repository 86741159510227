import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar, Divider, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import { fetchOwnerInfo } from '../../services/firebase/ownerService';
import { useNavigate } from 'react-router-dom';

export const ProfileSection = ({ onLogout, isCollapsed }) => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('Utilisateur');
    const [userPhoto, setUserPhoto] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const ownerInfo = await fetchOwnerInfo(currentUser.uid);
                if (ownerInfo) {
                    setUserName(ownerInfo.firstName + " " + ownerInfo.lastName || 'Utilisateur');
                    setUserPhoto(ownerInfo.profilePhotoUrl || '');
                }
            }
        });

        return () => unsubscribe();
    }, []);

    // Fonction pour naviguer vers le profil
    const handleProfileClick = () => {
        navigate('/dashboard/profile');
    };

    // En mode réduit, on n'affiche rien
    if (isCollapsed) {
        return null;
    }

    // En mode normal, on affiche le profil complet avec les boutons
    return (
        <Box sx={{ 
            m: 3, 
            p: 2, 
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.03)',
            border: '1px solid',
            borderColor: 'divider'
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Avatar 
                    src={userPhoto}
                    alt={userName}
                    sx={{ 
                        width: 40, 
                        height: 40,
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                    }}
                />
                <Box sx={{ ml: 2 }}>
                    <Typography 
                        variant="subtitle1" 
                        sx={{ 
                            fontWeight: 'bold',
                            fontSize: '0.9rem'
                        }}
                    >
                        {userName}
                    </Typography>
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontSize: '0.75rem' }}
                    >
                        Mon compte
                    </Typography>
                </Box>
            </Box>
            
            <Divider sx={{ my: 1.5 }} />
            
            {/* Boutons de navigation et déconnexion */}
            <Stack spacing={1}>
                {/* Bouton Mon Profil */}
                <Button 
                    variant="outlined" 
                    color="primary"
                    size="small"
                    startIcon={<PersonIcon />}
                    onClick={handleProfileClick}
                    fullWidth
                    sx={{ 
                        textTransform: 'none',
                        borderRadius: '6px'
                    }}
                >
                    Mon profil
                </Button>
                
                {/* Bouton de déconnexion */}
                <Button 
                    variant="outlined" 
                    color="error"
                    size="small"
                    startIcon={<LogoutIcon />}
                    onClick={onLogout}
                    fullWidth
                    sx={{ 
                        textTransform: 'none',
                        borderRadius: '6px'
                    }}
                >
                    Se déconnecter
                </Button>
            </Stack>
        </Box>
    );
}; 