import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Avatar,
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  Paper,
  useTheme,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { fetchCompanyInfo, editCompanyInfo } from '../../../../../services/firebase/companyService';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const auth = getAuth();
const storage = getStorage();

function CompanyProfile() {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [logoUrl, setLogoUrl] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [workforce, setWorkforce] = useState('');
  const [address, setAddress] = useState('');
  const [sector, setSector] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          setLoading(true);
          const companyData = await fetchCompanyInfo(user.uid);
          if (companyData) {
            setName(companyData.name || '');
            setLogoUrl(companyData.logoUrl || null);
            setLogoPreview(companyData.logoUrl || null);
            setDescription(companyData.description || '');
            setWebsite(companyData.website || '');
            setWorkforce(companyData.workforce || '');
            setAddress(companyData.address || '');
            setSector(companyData.sector || '');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        setError('Utilisateur non authentifié');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const uploadPhoto = async (file, userId) => {
    const photoRef = ref(storage, `companyLogos/${userId}`);
    await uploadBytes(photoRef, file);
    return getDownloadURL(photoRef);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (logoPreview && logoPreview.startsWith('blob:')) {
        URL.revokeObjectURL(logoPreview);
      }
      setLogoPreview(URL.createObjectURL(file));
      setLogoUrl(file); // Stocker le fichier pour l'upload
      setIsModified(true);
    }
  };

  const handleInputChange = (setter, value, initialValue) => {
    setter(value);
    if (value !== initialValue) {
      setIsModified(true);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setSuccess(false);

      const userId = auth.currentUser?.uid;
      if (!userId) {
        throw new Error('Utilisateur non authentifié');
      }

      let newLogoUrl = logoUrl;

      if (logoUrl instanceof File) {
        newLogoUrl = await uploadPhoto(logoUrl, userId);
      }

      const updatedData = {
        name,
        logoUrl: newLogoUrl, // Utiliser l'URL Firebase, pas l'aperçu local
        description,
        website,
        workforce,
        address,
        sector,
      };

      await editCompanyInfo(userId, updatedData);
      setLogoPreview(newLogoUrl);
      setSuccess(true);
      setIsModified(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Style commun pour les TextField qui s'adapte au mode sombre
  const textFieldStyle = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f9f9f9',
    borderRadius: 1,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, px: 2 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          borderRadius: 2, 
          maxWidth: 800, 
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <IconButton component="label">
            <Avatar
              src={logoPreview}
              sx={{ width: 100, height: 100, mr: 2, boxShadow: 3, border: '3px solid #1976d2' }}
            >
              <CameraAltIcon fontSize="large" />
            </Avatar>
            <input type="file" hidden accept="image/*" onChange={handleLogoChange} />
          </IconButton>
          <Box>
            <Typography variant="h6" fontWeight={600}>
              Informations sur l'entreprise
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Personnalisez les informations de votre entreprise.
            </Typography>
          </Box>
        </Box>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>Informations mises à jour avec succès.</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom de l'entreprise"
              fullWidth
              value={name}
              onChange={(e) => handleInputChange(setName, e.target.value, name)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => handleInputChange(setDescription, e.target.value, description)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Site Web"
              fullWidth
              value={website}
              onChange={(e) => handleInputChange(setWebsite, e.target.value, website)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Effectif"
              fullWidth
              value={workforce}
              onChange={(e) => handleInputChange(setWorkforce, e.target.value, workforce)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Secteur"
              fullWidth
              value={sector}
              onChange={(e) => handleInputChange(setSector, e.target.value, sector)}
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Adresse complète"
              fullWidth
              value={address}
              onChange={(e) => handleInputChange(setAddress, e.target.value, address)}
              sx={textFieldStyle}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 2, textTransform: 'none' }}
            disabled={!isModified}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
            onClick={handleSave}
            disabled={!isModified}
          >
            Enregistrer
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default CompanyProfile;
