import React from "react";
import { useMediaQuery, Box, Drawer, IconButton, Tooltip } from "@mui/material";
import SidebarItems from "./SidebarItems";
import { ProfileSection } from "./ProfileSection";
import Logo from "./Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Propriétés du composant
// isMobileSidebarOpen: état d'ouverture du menu sur mobile
// onSidebarClose: fonction pour fermer le menu
// isSidebarOpen: état d'ouverture du menu sur desktop
// onLogout: fonction pour se déconnecter
// toggleCollapse: fonction pour réduire/agrandir manuellement le menu
// manualCollapsed: état de réduction manuelle du menu

const ModernSidebar = ({
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
  onLogout,
  toggleCollapse,
  manualCollapsed,
}) => {
  // Points de rupture pour les différentes tailles d'écran
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  
  // Largeurs du menu selon son état
  const sidebarWidth = "270px";
  const collapsedSidebarWidth = "70px";
  
  // Définit si on est en mode réduit (par taille d'écran ou manuellement)
  const autoCollapsed = mdUp && !lgUp; // Mode réduit auto sur écrans moyens
  // Le menu est réduit si l'utilisateur a choisi de le réduire manuellement OU si l'écran est de taille moyenne
  const isCollapsed = manualCollapsed || (autoCollapsed && !manualCollapsed);

  // Styles personnalisés pour la scrollbar
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#eff2f7',
      borderRadius: '10px',
    },
  };

  // Composant Sidebar pour Desktop et tablettes
  if (mdUp) {
    return (
      <Box
        sx={{
          width: isCollapsed ? collapsedSidebarWidth : sidebarWidth,
          flexShrink: 0,
          transition: 'width 0.3s ease',
        }}
      >
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          variant="permanent"
          PaperProps={{
            sx: {
              width: isCollapsed ? collapsedSidebarWidth : sidebarWidth,
              boxSizing: "border-box",
              border: "0px",
              boxShadow: (theme) => theme.shadows[8],
              transition: 'width 0.3s ease',
              overflowX: 'hidden',
              ...scrollbarStyles,
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Logo avec les paramètres isCollapsed et toggleCollapse */}
            <Logo 
              isCollapsed={isCollapsed} 
              toggleCollapse={toggleCollapse}
            />
            
            {/* Éléments du menu, avec le mode réduit indiqué */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', mt: 1 }}>
              <SidebarItems isCollapsed={isCollapsed} />
            </Box>

            {/* Section profil en bas */}
            <ProfileSection onLogout={onLogout} isCollapsed={isCollapsed} />
          </Box>
        </Drawer>
      </Box>
    );
  }

  // Composant Sidebar pour Mobile
  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      variant="temporary"
      PaperProps={{
        sx: {
          width: sidebarWidth,
          boxShadow: (theme) => theme.shadows[8],
          ...scrollbarStyles,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Logo avec les paramètres pour mobile */}
        <Logo 
          isCollapsed={false} 
          toggleCollapse={() => {}}
        />
        
        {/* Éléments du menu */}
        <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: 1 }}>
          <SidebarItems toggleMobileSidebar={onSidebarClose} isCollapsed={false} />
        </Box>

        {/* Section profil en bas */}
        <ProfileSection onLogout={onLogout} isCollapsed={false} />
      </Box>
    </Drawer>
  );
};

export default ModernSidebar; 