import React, { useEffect, useRef, useState } from 'react';
import { Box, Paper, Button } from '@mui/material';
import { Canvas } from 'fabric';
import Settings from './Settings';
import SideMenu from './SideMenu';
import CanvasManager from './CanvasManager';
import CanvasControls from './CanvasControls';
import ShapesIcon from '@mui/icons-material/Category';
import MediaIcon from '@mui/icons-material/PermMedia';
import TextIcon from '@mui/icons-material/TextFields';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const FabricEditor = ({ imageUrl, onSave }) => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if (!canvas && canvasRef.current) {
      const initCanvas = new Canvas(canvasRef.current, {
        width: 800,
        height: 600,
        selection: true,
        preserveObjectStacking: true,
        enableRetinaScaling: true,
        skipTargetFind: false,
        isDrawingMode: false,
        stopContextMenu: false,
      });

      initCanvas.backgroundColor = '#fff';
      setCanvas(initCanvas);
    }
  }, [canvas]);

  const handleMenuClick = (menuType) => {
    setActiveMenu(activeMenu === menuType ? null : menuType);
    setSelectedType(null);
  };

  const handleCanvasClick = () => {
    setSelectedType(selectedType === 'canvas' ? null : 'canvas');
    setActiveMenu(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        p: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 1,
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      {/* Toolbar */}
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 1,
          width: '100%',
          justifyContent: 'flex-start',
          borderRadius: 1,
          mb: 1,
          backgroundColor: '#fff'
        }}
      >
        <Button
          variant={selectedType === 'canvas' ? 'contained' : 'outlined'}
          onClick={handleCanvasClick}
          startIcon={<AspectRatioIcon />}
          size="small"
        >
          Canvas
        </Button>
        <Button
          variant={activeMenu === 'shapes' ? 'contained' : 'outlined'}
          onClick={() => handleMenuClick('shapes')}
          startIcon={<ShapesIcon />}
          size="small"
        >
          Formes
        </Button>
        <Button
          variant={activeMenu === 'media' ? 'contained' : 'outlined'}
          onClick={() => handleMenuClick('media')}
          startIcon={<MediaIcon />}
          size="small"
        >
          Médias
        </Button>
        <Button
          variant={activeMenu === 'text' ? 'contained' : 'outlined'}
          onClick={() => handleMenuClick('text')}
          startIcon={<TextIcon />}
          size="small"
        >
          Texte
        </Button>
      </Paper>

      {/* Main Content Area */}
      <Box sx={{ 
        display: 'flex', 
        gap: 2,
        flex: 1,
        position: 'relative',
        height: 'calc(100vh - 120px)',
        overflow: 'hidden'
      }}>
        {/* Side Menu */}
        <Box
          sx={{
            width: '220px',
            minWidth: '220px',
            visibility: activeMenu ? 'visible' : 'hidden'
          }}
        >
          {activeMenu && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: 1,
                boxShadow: 1,
                overflow: 'hidden'
              }}
            >
              <SideMenu 
                type={activeMenu} 
                canvas={canvas} 
                canvasRef={canvasRef}
              />
            </Box>
          )}
        </Box>
        
        {/* Canvas Container */}
        <Box 
          sx={{ 
            flex: 1,
            position: 'relative',
            backgroundColor: '#f5f5f5',
            overflow: 'hidden',
            margin: '0 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div 
            style={{ 
              position: 'relative',
              width: '800px',
              height: '600px',
              transformOrigin: 'center'
            }}
          >
            <canvas 
              ref={canvasRef} 
              style={{ 
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                display: 'block',
                width: '100%',
                height: '100%'
              }} 
            />
          </div>
        </Box>
        
        {/* Settings Panel */}
        <Box 
          sx={{ 
            width: '350px',
            minWidth: '350px',
            height: '100%',
            backgroundColor: '#fff',
            borderRadius: 1,
            boxShadow: 1,
            overflow: 'auto'
          }}
        >
          <Box sx={{ p: 2 }}>
            <Settings canvas={canvas} selectedType={selectedType} />
          </Box>
        </Box>

        {/* Canvas Controls */}
        {canvas && <CanvasControls canvas={canvas} />}

        {/* Canvas Manager */}
        {canvas && (
          <CanvasManager 
            canvas={canvas}
            canvasRef={canvasRef}
          />
        )}
      </Box>
    </Box>
  );
};

export default FabricEditor;
