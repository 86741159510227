import React from 'react';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    Box,
    Divider
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

function ContextMenuCanvas({ 
    contextMenu, 
    onClose, 
    onCopy, 
    onCut, 
    onDelete, 
    onBringToFront, 
    onSendToBack,
    onSelectAll,
    onDuplicate
}) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const cmdKey = isMac ? '⌘' : 'Ctrl';

    const menuItems = [
        {
            icon: <ContentCopyIcon fontSize="small" />,
            label: 'Copier',
            onClick: onCopy,
            shortcut: `${cmdKey} + C`
        },
        {
            icon: <ContentCutIcon fontSize="small" />,
            label: 'Couper',
            onClick: onCut,
            shortcut: `${cmdKey} + X`
        },
        {
            icon: <ContentCopyOutlinedIcon fontSize="small" />,
            label: 'Dupliquer',
            onClick: onDuplicate,
            shortcut: `${cmdKey} + D`
        },
        {
            icon: <DeleteIcon fontSize="small" />,
            label: 'Supprimer',
            onClick: onDelete,
            shortcut: 'Del'
        },
        { divider: true },
        {
            icon: <VerticalAlignTopIcon fontSize="small" />,
            label: 'Mettre en avant',
            onClick: onBringToFront,
            shortcut: `${cmdKey} + ]`
        },
        {
            icon: <VerticalAlignBottomIcon fontSize="small" />,
            label: 'Mettre en arrière',
            onClick: onSendToBack,
            shortcut: `${cmdKey} + [`
        },
        { divider: true },
        {
            icon: <SelectAllIcon fontSize="small" />,
            label: 'Tout sélectionner',
            onClick: onSelectAll,
            shortcut: `${cmdKey} + A`
        }
    ];

    return (
        <Menu
            open={contextMenu !== null}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? {
                top: contextMenu.mouseY,
                left: contextMenu.mouseX
            } : undefined}
            slotProps={{
                paper: {
                    sx: {
                        maxHeight: '400px',
                        width: '250px',
                        zIndex: 99999,
                        position: 'fixed'
                    }
                }
            }}
            sx={{
                zIndex: 99999,
                position: 'fixed'
            }}
            MenuListProps={{
                sx: { zIndex: 99999 }
            }}
        >
            {menuItems.map((item, index) => (
                item.divider ? (
                    <Divider key={`divider-${index}`} />
                ) : (
                    <MenuItem 
                        key={item.label} 
                        onClick={item.onClick}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 2
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="inherit">
                                {item.label}
                            </Typography>
                        </Box>
                        {item.shortcut && (
                            <Typography 
                                variant="body2" 
                                color="text.secondary"
                                sx={{ 
                                    ml: 2,
                                    opacity: 0.7,
                                    fontSize: '0.8rem'
                                }}
                            >
                                {item.shortcut}
                            </Typography>
                        )}
                    </MenuItem>
                )
            ))}
        </Menu>
    );
}

export default ContextMenuCanvas; 