import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Slider,
  Tooltip,
  Paper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

const CanvasControls = ({ canvas }) => {
  const [zoom, setZoom] = useState(100);
  const minZoom = 10;
  const maxZoom = 400;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const viewContainerRef = useRef(null);

  useEffect(() => {
    if (!canvas) return;

    // Initialiser la référence du conteneur
    viewContainerRef.current = canvas.wrapperEl.parentElement;

    // Centrer initialement le canvas
    const rect = viewContainerRef.current.getBoundingClientRect();
    const centerX = (rect.width - canvas.width) / 2;
    const centerY = (rect.height - canvas.height) / 2;
    setPosition({ x: centerX, y: centerY });
  }, [canvas]);

  // Mettre à jour la transformation quand position ou scale change
  useEffect(() => {
    if (!viewContainerRef.current) return;
    viewContainerRef.current.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scale})`;
    viewContainerRef.current.style.cursor = scale > 1 ? 'grab' : 'default';
  }, [position, scale]);

  useEffect(() => {
    if (!canvas) return;

    let isDragging = false;
    let lastX, lastY;
    const viewContainer = viewContainerRef.current;

    const handleMouseDown = (e) => {
      if (e.button === 0 && scale > 1) {
        isDragging = true;
        lastX = e.clientX;
        lastY = e.clientY;
        viewContainer.style.cursor = 'grabbing';
      }
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const deltaX = e.clientX - lastX;
      const deltaY = e.clientY - lastY;

      setPosition(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY
      }));

      lastX = e.clientX;
      lastY = e.clientY;
    };

    const handleMouseUp = () => {
      isDragging = false;
      viewContainer.style.cursor = scale > 1 ? 'grab' : 'default';
    };

    const handleWheel = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const rect = viewContainer.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        // Calculer le nouveau zoom
        const delta = e.deltaY;
        const newScale = delta > 0 
          ? Math.max(scale * 0.9, minZoom / 100)
          : Math.min(scale * 1.1, maxZoom / 100);

        // Calculer la nouvelle position pour maintenir le point de zoom
        const scaleRatio = newScale / scale;
        const newX = mouseX - (mouseX - position.x) * scaleRatio;
        const newY = mouseY - (mouseY - position.y) * scaleRatio;

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setZoom(newScale * 100);
      }
    };

    viewContainer.addEventListener('mousedown', handleMouseDown);
    viewContainer.addEventListener('mousemove', handleMouseMove);
    viewContainer.addEventListener('mouseup', handleMouseUp);
    viewContainer.addEventListener('mouseleave', handleMouseUp);
    viewContainer.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      if (viewContainer) {
        viewContainer.removeEventListener('mousedown', handleMouseDown);
        viewContainer.removeEventListener('mousemove', handleMouseMove);
        viewContainer.removeEventListener('mouseup', handleMouseUp);
        viewContainer.removeEventListener('mouseleave', handleMouseUp);
        viewContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, [canvas, scale, position]);

  const handleZoomIn = () => {
    const newScale = Math.min(scale * 1.1, maxZoom / 100);
    const rect = viewContainerRef.current.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const newX = centerX - (centerX - position.x) * (newScale / scale);
    const newY = centerY - (centerY - position.y) * (newScale / scale);

    setScale(newScale);
    setPosition({ x: newX, y: newY });
    setZoom(newScale * 100);
  };

  const handleZoomOut = () => {
    const newScale = Math.max(scale * 0.9, minZoom / 100);
    const rect = viewContainerRef.current.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const newX = centerX - (centerX - position.x) * (newScale / scale);
    const newY = centerY - (centerY - position.y) * (newScale / scale);

    setScale(newScale);
    setPosition({ x: newX, y: newY });
    setZoom(newScale * 100);
  };

  const handleResetZoom = () => {
    const rect = viewContainerRef.current.getBoundingClientRect();
    const centerX = (rect.width - canvas.width) / 2;
    const centerY = (rect.height - canvas.height) / 2;
    setScale(1);
    setPosition({ x: centerX, y: centerY });
    setZoom(100);
  };

  const handleSliderChange = (event, newValue) => {
    const newScale = newValue / 100;
    const rect = viewContainerRef.current.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const newX = centerX - (centerX - position.x) * (newScale / scale);
    const newY = centerY - (centerY - position.y) * (newScale / scale);

    setScale(newScale);
    setPosition({ x: newX, y: newY });
    setZoom(newValue);
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: 2,
        borderRadius: 2,
        zIndex: 9999
      }}
    >
      <Tooltip title="Zoom arrière">
        <IconButton size="small" onClick={handleZoomOut}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>

      <Slider
        value={zoom}
        min={minZoom}
        max={maxZoom}
        onChange={handleSliderChange}
        sx={{ width: 120 }}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />

      <Tooltip title="Zoom avant">
        <IconButton size="small" onClick={handleZoomIn}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Réinitialiser le zoom">
        <IconButton size="small" onClick={handleResetZoom}>
          <CenterFocusStrongIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default CanvasControls; 