// MediaPreview.js
import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, IconButton, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import 'react-image-lightbox/style.css';
import { storage } from '../../../config/firebaseConfig';
import ImageEditorModal from '../../ImageEditor/ImageEditorModal';

function MediaPreview({ mediaFiles, isEditing, onRemoveMedia, onAddMedia, onUpdateMedia }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogIndex, setDialogIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMediaClick = (index) => {
    setDialogIndex(index);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleNextMedia = () => {
    setDialogIndex((dialogIndex + 1) % mediaFiles.length);
  };

  const handlePrevMedia = () => {
    setDialogIndex((dialogIndex + mediaFiles.length - 1) % mediaFiles.length);
  };

  const handleAddMedia = (event) => {
    const newFiles = Array.from(event.target.files);
    onAddMedia(newFiles);
  };

  const handleEditClick = (imageUrl) => {
    console.log('URL de l\'image à éditer:', imageUrl);
    setSelectedImage(imageUrl);
    setIsEditorOpen(true);
  };

  const handleSaveEdit = (editedImageUrl) => {
    console.log('Sauvegarde de l\'image éditée:', editedImageUrl);
    if (onUpdateMedia) {
      onUpdateMedia(selectedImage, editedImageUrl);
    }
    // Nettoyer l'URL de la copie locale
    if (selectedImage && selectedImage.startsWith('blob:')) {
      URL.revokeObjectURL(selectedImage);
    }
    setIsEditorOpen(false);
    setSelectedImage(null);
  };

  // Nettoyer les URLs lors du démontage du composant
  useEffect(() => {
    return () => {
      if (selectedImage && selectedImage.startsWith('blob:')) {
        URL.revokeObjectURL(selectedImage);
      }
    };
  }, [selectedImage]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
        {mediaFiles && Array.isArray(mediaFiles) && mediaFiles.length > 0 && (
          mediaFiles.map((file, index) => {
            const mediaUrl = file?.url || URL.createObjectURL(file);

            return (
              <Box key={index} sx={{ position: 'relative', width: isMobile ? 100 : 150, height: isMobile ? 100 : 150, flexShrink: 0 }}>
                {file?.type?.startsWith("image") ? (
                  <img
                    src={mediaUrl}
                    alt={`media-${index}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => handleMediaClick(index)}
                  />
                ) : (
                  <video
                    src={mediaUrl}
                    controls
                    style={{ width: '100%', height: '100%', borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => handleMediaClick(index)}
                  />
                )}
                {isEditing && (
                  <IconButton
                    onClick={() => onRemoveMedia(index)}
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: -8, 
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? 'rgba(50, 50, 50, 0.7)' 
                        : 'rgba(255, 255, 255, 0.7)',
                      boxShadow: 2,
                      transform: 'translate(25%, -25%)',
                      border: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <CloseIcon sx={{ color: theme.palette.error.main }} />
                  </IconButton>
                )}
                {isEditing && file?.type?.startsWith("image") && (
                  <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                    <IconButton
                      sx={{
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'rgba(50, 50, 50, 0.8)' 
                          : 'rgba(255, 255, 255, 0.8)',
                        '&:hover': {
                          backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(70, 70, 70, 0.9)' 
                            : 'rgba(255, 255, 255, 0.9)',
                        },
                      }}
                      onClick={() => handleEditClick(mediaUrl)}
                    >
                      <EditIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            );
          })
        )}
      </Box>

      {/* Bouton pour ajouter un média en mode édition */}
      {isEditing && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            component="label"
            sx={{
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.action.hover,
              }
            }}
          >
            Ajouter un média
            <input
              type="file"
              accept="image/*,video/*"
              multiple
              hidden
              onChange={handleAddMedia}
            />
          </Button>
        </Box>
      )}

      {/* Dialog pour l'affichage des médias en grand */}
      {mediaFiles[dialogIndex] && (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogContent sx={{ 
            position: 'relative', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#000',
            padding: 0
          }}>
            <IconButton
              onClick={handleCloseDialog}
              sx={{ position: 'absolute', top: 16, right: 16, color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>
            {mediaFiles[dialogIndex]?.type?.startsWith("image") ? (
              <img
                src={mediaFiles[dialogIndex]?.url || URL.createObjectURL(mediaFiles[dialogIndex])}
                alt={`dialog-media-${dialogIndex}`}
                style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: 8 }}
              />
            ) : (
              <video
                src={mediaFiles[dialogIndex]?.url || URL.createObjectURL(mediaFiles[dialogIndex])}
                controls
                style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: 8 }}
              />
            )}
            <Box sx={{ position: 'absolute', bottom: 16, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <IconButton onClick={handlePrevMedia} sx={{ color: '#fff' }}>
                ❮
              </IconButton>
              <IconButton onClick={handleNextMedia} sx={{ color: '#fff' }}>
                ❯
              </IconButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <ImageEditorModal
        open={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        imageUrl={selectedImage}
        onSave={handleSaveEdit}
      />
    </>
  );
}

export default MediaPreview;
