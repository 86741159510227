import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Slider,
  Button,
  IconButton
} from '@mui/material';
import { 
  Rect, 
  Image, 
  Shadow, 
  Gradient, 
  IText,
  filters 
} from 'fabric';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CanvasSettings from './CanvasSettings';

function Settings({ canvas, selectedType }) {
  const [selectedObject, setSelectedObject] = useState(null);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [radius, setRadius] = useState("");
  const [color, setColor] = useState("#000000");
  const [opacity, setOpacity] = useState(100);
  const [cornerRadius, setCornerRadius] = useState(0);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeType, setStrokeType] = useState("solid");
  const [shadowColor, setShadowColor] = useState("#000000");
  const [shadowBlur, setShadowBlur] = useState(0);
  const [shadowOffsetX, setShadowOffsetX] = useState(0);
  const [shadowOffsetY, setShadowOffsetY] = useState(0);
  const [gradientType, setGradientType] = useState("none");
  const [gradientColor1, setGradientColor1] = useState("#000000");
  const [gradientColor2, setGradientColor2] = useState("#ffffff");
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(20);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [blur, setBlur] = useState(0);
  const [sepia, setSepia] = useState(0);
  const [grayscale, setGrayscale] = useState(false);
  const [invert, setInvert] = useState(false);

  const fontFamilies = [
    "Arial",
    "Times New Roman",
    "Courier New",
    "Georgia",
    "Verdana",
    "Helvetica"
  ];

  const strokeTypes = [
    { value: "solid", label: "Ligne continue" },
    { value: "dashed", label: "Tirets" },
    { value: "dotted", label: "Pointillés" }
  ];

  const gradientTypes = [
    { value: "none", label: "Aucun" },
    { value: "linear", label: "Linéaire" },
    { value: "radial", label: "Radial" }
  ];

  useEffect(() => {
    if (!canvas) return;

    const handleDblClick = (e) => {
      const activeObject = e.target;
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'i-text')) {
        activeObject.enterEditing();
        activeObject.selectAll();
        canvas.requestRenderAll();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        const activeObject = canvas.getActiveObject();
        if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'i-text') && activeObject.isEditing) {
          activeObject.exitEditing();
          canvas.requestRenderAll();
        }
      }
    };

    canvas.on('mouse:dblclick', handleDblClick);
    window.addEventListener('keydown', handleKeyDown);

    canvas.set({
      selection: true,
      interactive: true
    });

    const updateObjectProperties = (selected) => {
      if (!selected) {
        setSelectedObject(null);
        resetProperties();
        return;
      }
      
      setSelectedObject(selected);
      setWidth(selected.width || "");
      setHeight(selected.height || "");
      setRadius(selected.radius || "");
      setOpacity(selected.opacity * 100);
      setCornerRadius(selected.rx || 0);
      setStrokeWidth(selected.strokeWidth || 0);
      setStrokeColor(selected.stroke || "#000000");
      setStrokeType(selected.strokeDashArray ? 
        (selected.strokeDashArray[0] === 2 ? "dotted" : "dashed") : "solid"
      );
      setShadowColor(selected.shadow?.color || "#000000");
      setShadowBlur(selected.shadow?.blur || 0);
      setShadowOffsetX(selected.shadow?.offsetX || 0);
      setShadowOffsetY(selected.shadow?.offsetY || 0);

      // Mise à jour du type de remplissage
      if (selected.fill === 'transparent' || selected.fill === null) {
        setGradientType('transparent');
      } else if (typeof selected.fill === 'string') {
        setGradientType('none');
        setColor(selected.fill);
      } else {
        setGradientType(selected.fill.type === 'linear' ? 'linear' : 'radial');
        if (selected.fill.colorStops) {
          setGradientColor1(selected.fill.colorStops[0].color);
          setGradientColor2(selected.fill.colorStops[1].color);
        }
      }

      if (selected.type === 'image') {
        setWidth(Math.round(selected.getScaledWidth()));
        setHeight(Math.round(selected.getScaledHeight()));
        setCornerRadius(selected.clipPath?.rx || 0);
        const filters = selected.filters || [];
        filters.forEach(filter => {
          if (filter.type === 'Brightness') setBrightness(filter.brightness * 100);
          if (filter.type === 'Contrast') setContrast(filter.contrast * 100);
          if (filter.type === 'Saturation') setSaturation(filter.saturation * 100);
          if (filter.type === 'Blur') setBlur(filter.blur * 100);
          if (filter.type === 'Sepia') setSepia(filter.sepia * 100);
          if (filter.type === 'Grayscale') setGrayscale(true);
          if (filter.type === 'Invert') setInvert(true);
        });
      } else if (selected.type === 'rect') {
        setWidth(Math.round(selected.getScaledWidth()));
        setHeight(Math.round(selected.getScaledHeight()));
        setCornerRadius(selected.cornerRadius || 0);
        setColor(selected.fill);
      } else if (selected.type === 'circle') {
        setRadius(Math.round(selected.getScaledWidth() / 2));
        setColor(selected.fill);
      } else if (selected.type === 'triangle') {
        setWidth(Math.round(selected.getScaledWidth()));
        setHeight(Math.round(selected.getScaledHeight()));
        setColor(selected.fill);
      } else if (selected.type === 'textbox') {
        setText(selected.text);
        setFontSize(selected.fontSize);
        setFontFamily(selected.fontFamily);
        setColor(selected.fill);
      }
    };

    const handleObjectSelection = (e) => {
      const selected = e.target || e.selected?.[0];
      updateObjectProperties(selected);
    };

    const handleSelectionCleared = () => {
      updateObjectProperties(null);
    };

    canvas.on('selection:created', handleObjectSelection);
    canvas.on('selection:updated', handleObjectSelection);
    canvas.on('selection:cleared', handleSelectionCleared);
    canvas.on('object:modified', handleObjectSelection);

    const handleScaling = (e) => {
      const obj = e.target;
      if (!obj || (obj.type !== 'rect' && obj.type !== 'image')) return;

      const currentRadius = obj.type === 'image' ? 
        (obj.clipPath?.rx || 0) : 
        (obj.rx || 0);

      if (currentRadius === 0) return;

      const maxRadius = Math.min(obj.width * obj.scaleX, obj.height * obj.scaleY) / 2;
      const newRadius = Math.min(currentRadius, maxRadius);

      if (obj.type === 'image' && obj.clipPath) {
        obj.clipPath.set({
          width: obj.width,
          height: obj.height,
          rx: newRadius,
          ry: newRadius,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY
        });
      } else if (obj.type === 'rect') {
        obj.set({
          rx: newRadius,
          ry: newRadius
        });
      }

      setCornerRadius(newRadius);
      canvas.requestRenderAll();
    };

    canvas.on('object:scaling', handleScaling);
    canvas.on('object:modified', handleScaling);

    canvas.on('object:selected', handleObjectSelection);
    canvas.on('selection:cleared', handleSelectionCleared);

    const handleObjectModification = (e) => {
      const obj = e.target;
      if (!obj) return;

      // Restaurer les propriétés de bordure
      if (obj.strokeWidth > 0) {
        obj.set({
          stroke: obj.stroke,
          strokeWidth: obj.strokeWidth,
          strokeDashArray: obj.strokeDashArray
        });
      }

      // Restaurer les coins arrondis
      if (obj.type === 'image' && obj.clipPath) {
        const rect = obj.clipPath;
        rect.set({
          width: obj.width,
          height: obj.height,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY
        });
      }

      canvas.requestRenderAll();
    };

    canvas.on('object:moving', handleObjectModification);
    canvas.on('object:scaling', handleObjectModification);
    canvas.on('object:modified', handleObjectModification);

    return () => {
      canvas.off('selection:created', handleObjectSelection);
      canvas.off('selection:updated', handleObjectSelection);
      canvas.off('selection:cleared', handleSelectionCleared);
      canvas.off('object:modified', handleObjectSelection);
      canvas.off('object:scaling', handleScaling);
      canvas.off('object:selected', handleObjectSelection);
      canvas.off('selection:cleared', handleSelectionCleared);
      canvas.off('object:moving', handleObjectModification);
      canvas.off('object:scaling', handleObjectModification);
      canvas.off('object:modified', handleObjectModification);
      canvas.off('mouse:dblclick', handleDblClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas]);

  const resetProperties = () => {
    setWidth("");
    setHeight("");
    setRadius("");
    setColor("#000000");
    setOpacity(100);
    setCornerRadius(0);
    setStrokeWidth(0);
    setStrokeColor("#000000");
    setStrokeType("solid");
    setShadowColor("#000000");
    setShadowBlur(0);
    setShadowOffsetX(0);
    setShadowOffsetY(0);
    setGradientType("none");
    setGradientColor1("#000000");
    setGradientColor2("#ffffff");
    setText("");
    setFontSize(20);
    setFontFamily("Arial");
    setBrightness(0);
    setContrast(0);
    setSaturation(0);
    setBlur(0);
    setSepia(0);
    setGrayscale(false);
    setInvert(false);
  };

  const handleOpacityChange = (e) => {
    const value = parseFloat(e.target.value) / 100;
    setOpacity(e.target.value);
    if (selectedObject) {
      selectedObject.set({ opacity: value });
      canvas.renderAll();
    }
  };

  const handleCornerRadiusChange = (e) => {
    const value = parseInt(e.target.value);
    const selected = canvas.getActiveObject();
    if (!selected || (selected.type !== 'rect' && selected.type !== 'image')) return;

    const maxRadius = Math.min(selected.width * selected.scaleX, selected.height * selected.scaleY) / 2;
    const newRadius = Math.min(value, maxRadius);

    if (selected.type === 'image') {
      // Pour les images, créer ou mettre à jour le clipPath
      const rect = selected.clipPath || new Rect({
        width: selected.width,
        height: selected.height,
        scaleX: selected.scaleX,
        scaleY: selected.scaleY,
        originX: 'center',
        originY: 'center'
      });
      
      rect.set({
        rx: newRadius,
        ry: newRadius
      });
      
      selected.clipPath = rect;
    } else {
      // Pour les rectangles, mettre à jour directement rx et ry
      selected.set({
        rx: newRadius,
        ry: newRadius
      });
    }

    setCornerRadius(newRadius);
    canvas.requestRenderAll();
  };

  const handleStrokeWidthChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setStrokeWidth(value);
    if (selectedObject) {
      selectedObject.set({ strokeWidth: value });
      canvas.renderAll();
    }
  };

  const handleStrokeColorChange = (e) => {
    const value = e.target.value;
    setStrokeColor(value);
    if (selectedObject) {
      selectedObject.set({ stroke: value });
      canvas.renderAll();
    }
  };

  const handleStrokeTypeChange = (e) => {
    const value = e.target.value;
    setStrokeType(value);
    if (selectedObject) {
      let dashArray;
      switch (value) {
        case 'dashed':
          dashArray = [10, 5];
          break;
        case 'dotted':
          dashArray = [2, 2];
          break;
        default:
          dashArray = null;
      }
      selectedObject.set({ strokeDashArray: dashArray });
      canvas.renderAll();
    }
  };

  const handleShadowChange = () => {
    const selected = canvas.getActiveObject();
    if (selected) {
      const shadow = new Shadow({
        color: shadowColor,
        blur: shadowBlur,
        offsetX: shadowOffsetX,
        offsetY: shadowOffsetY
      });
      selected.set('shadow', shadow);
      canvas.requestRenderAll();
    }
  };

  const handleGradientChange = (type) => {
    if (!selectedObject) return;

    setGradientType(type);

    if (type === 'none') {
      selectedObject.set('fill', null);
    } else if (type === 'color') {
      selectedObject.set('fill', '#FF0000');
    } else {
      const coords = selectedObject.type === 'circle' 
        ? { x1: 0, y1: 0, x2: selectedObject.radius * 2, y2: selectedObject.radius * 2 }
        : { x1: 0, y1: 0, x2: selectedObject.width, y2: selectedObject.height };

      const gradient = new Gradient({
        type: type,
        coords: coords,
        colorStops: [
          { offset: 0, color: '#000000' },
          { offset: 1, color: '#FFFFFF' }
        ]
      });
      selectedObject.set('fill', gradient);
    }

    canvas.requestRenderAll();
  };

  const handleAddImage = (e) => {
    if (!selectedObject) return;
    
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image(event.target.result);
        img.scaleToWidth(selectedObject.width);
        img.scaleToHeight(selectedObject.height);
        selectedObject.set('fill', img);
        canvas.requestRenderAll();
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddText = () => {
    if (!selectedObject) return;
    
    const textbox = new IText('Cliquez pour éditer', {
      left: selectedObject.left + selectedObject.width/2,
      top: selectedObject.top + selectedObject.height/2,
      fontSize: 20,
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      editable: true,
      editingBorderColor: '#1976d2',
      cursorColor: '#1976d2',
      cursorWidth: 2,
      selectionColor: 'rgba(25, 118, 210, 0.3)'
    });
    
    canvas.add(textbox);
    canvas.setActiveObject(textbox);
    textbox.enterEditing();
    canvas.requestRenderAll();
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    setText(value);
    if (selectedObject && selectedObject.type === 'textbox') {
      selectedObject.set({ text: value });
      canvas.renderAll();
    }
  };

  const handleFontSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setFontSize(value);
    if (selectedObject && selectedObject.type === 'textbox') {
      selectedObject.set({ fontSize: value });
      canvas.renderAll();
    }
  };

  const handleFontFamilyChange = (e) => {
    const value = e.target.value;
    setFontFamily(value);
    if (selectedObject && selectedObject.type === 'textbox') {
      selectedObject.set({ fontFamily: value });
      canvas.renderAll();
    }
  };

  const handleHeightChange = (e) => {
    const value = parseInt(e.target.value.replace(/,/g, ""), 10);
    setHeight(value);
    if (selectedObject && selectedObject.type === 'rect' && value > 0) {
      selectedObject.set({ height: value / selectedObject.scaleY });
      canvas.renderAll();
    }
  };

  const handleWidthChange = (e) => {
    const value = parseInt(e.target.value.replace(/,/g, ""), 10);
    setWidth(value);
    if (selectedObject && selectedObject.type === 'rect' && value > 0) {
      selectedObject.set({ width: value / selectedObject.scaleX });
      canvas.renderAll();
    }
  };

  const handleDiameterChange = (e) => {
    const value = parseInt(e.target.value.replace(/,/g, ""), 10);
    setRadius(value);
    if (selectedObject && selectedObject.type === 'circle' && value > 0) {
      selectedObject.set({ radius: value / 2 / selectedObject.scaleX });
      canvas.renderAll();
    }
  };

  const handleColorChange = (e) => {
    const value = e.target.value;
    setColor(value);
    if (selectedObject) {
      selectedObject.set({ fill: value });
      canvas.renderAll();
    }
  };

  const handleFilterChange = (filterType, value) => {
    const selected = canvas.getActiveObject();
    if (selected && selected.type === 'image') {
      let imageFilters = Array(7).fill(null);
      
      // Récupérer les filtres existants
      if (selected.filters) {
        selected.filters.forEach((filter, index) => {
          // Ne garder le filtre que s'il n'est pas celui qu'on modifie
          if (filter && index !== getFilterIndex(filterType)) {
            imageFilters[index] = filter;
          }
        });
      }
      
      // Ajouter ou supprimer le filtre selon la valeur
      switch (filterType) {
        case 'brightness':
          imageFilters[0] = value !== 0 ? new filters.Brightness({ brightness: value / 100 }) : null;
          setBrightness(value);
          break;
        case 'contrast':
          imageFilters[1] = value !== 0 ? new filters.Contrast({ contrast: value / 100 }) : null;
          setContrast(value);
          break;
        case 'saturation':
          imageFilters[2] = value !== 0 ? new filters.Saturation({ saturation: value / 100 }) : null;
          setSaturation(value);
          break;
        case 'blur':
          imageFilters[3] = value !== 0 ? new filters.Blur({ blur: Math.max(0, value) / 100 }) : null;
          setBlur(value);
          break;
        case 'sepia':
          imageFilters[4] = value !== 0 ? new filters.Sepia({ sepia: Math.max(0, value) / 100 }) : null;
          setSepia(value);
          break;
        case 'grayscale':
          imageFilters[5] = value ? new filters.Grayscale() : null;
          setGrayscale(value);
          break;
        case 'invert':
          imageFilters[6] = value ? new filters.Invert() : null;
          setInvert(value);
          break;
      }
      
      // Ne garder que les filtres non nuls
      selected.filters = imageFilters.filter(f => f !== null);
      
      // Appliquer les filtres et mettre à jour le canvas
      selected.applyFilters();
      canvas.requestRenderAll();
    }
  };

  const getFilterIndex = (filterType) => {
    switch (filterType) {
      case 'brightness': return 0;
      case 'contrast': return 1;
      case 'saturation': return 2;
      case 'blur': return 3;
      case 'sepia': return 4;
      case 'grayscale': return 5;
      case 'invert': return 6;
      default: return -1;
    }
  };

  const handleResetImage = () => {
    const selected = canvas.getActiveObject();
    if (selected && selected.type === 'image') {
      // Réinitialiser tous les filtres
      selected.filters = [];
      selected.applyFilters();
      canvas.requestRenderAll();

      // Réinitialiser les états
      setBrightness(0);
      setContrast(0);
      setSaturation(0);
      setBlur(0);
      setSepia(0);
      setGrayscale(false);
      setInvert(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {selectedType === 'canvas' ? (
        <CanvasSettings canvas={canvas} />
      ) : (
        <>
          {!selectedObject ? (
            <Typography variant="body1" color="text.secondary" align="center">
              Sélectionnez un élément pour modifier ses propriétés
            </Typography>
          ) : (
            <>
              <Typography variant="h6" color="text.primary">
                Propriétés de l'élément
              </Typography>

              {/* Dimensions */}
              {(selectedObject.type === 'rect' || selectedObject.type === 'triangle' || selectedObject.type === 'image') && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Largeur"
                    type="number"
                    value={width}
                    onChange={handleWidthChange}
                    fullWidth
                    size="small"
                  />
                  <TextField
                    label="Hauteur"
                    type="number"
                    value={height}
                    onChange={handleHeightChange}
                    fullWidth
                    size="small"
                  />
                </Box>
              )}

              {selectedObject.type === 'circle' && (
                <TextField
                  label="Diamètre"
                  type="number"
                  value={radius * 2}
                  onChange={handleDiameterChange}
                  fullWidth
                  size="small"
                />
              )}

              {/* Opacité */}
              <Box>
                <Typography gutterBottom>Opacité</Typography>
                <Slider
                  value={opacity}
                  onChange={handleOpacityChange}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}%`}
                />
              </Box>

              {/* Coins arrondis (rectangle et image) */}
              {(selectedObject.type === 'rect' || selectedObject.type === 'image') && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Coins arrondis
                  </Typography>
                  <TextField
                    type="number"
                    value={cornerRadius}
                    onChange={handleCornerRadiusChange}
                    fullWidth
                    size="small"
                    inputProps={{ 
                      min: 0, 
                      max: Math.min(
                        selectedObject.width * selectedObject.scaleX, 
                        selectedObject.height * selectedObject.scaleY
                      ) / 2 
                    }}
                  />
                </Box>
              )}

              {/* Bordures */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="subtitle2">Bordures</Typography>
                <TextField
                  label="Épaisseur"
                  type="number"
                  value={strokeWidth}
                  onChange={handleStrokeWidthChange}
                  size="small"
                  inputProps={{ min: 0 }}
                />
                <TextField
                  label="Couleur"
                  type="color"
                  value={strokeColor}
                  onChange={handleStrokeColorChange}
                  size="small"
                />
                <FormControl size="small">
                  <InputLabel>Type de trait</InputLabel>
                  <Select
                    value={strokeType}
                    onChange={handleStrokeTypeChange}
                    label="Type de trait"
                  >
                    {strokeTypes.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Filtres (uniquement pour les images) */}
              {selectedObject.type === 'image' && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography variant="subtitle2">Filtres</Typography>
                  
                  <Box>
                    <Typography variant="body2">Luminosité</Typography>
                    <Slider
                      value={brightness}
                      onChange={(e, value) => handleFilterChange('brightness', value)}
                      min={-100}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  <Box>
                    <Typography variant="body2">Contraste</Typography>
                    <Slider
                      value={contrast}
                      onChange={(e, value) => handleFilterChange('contrast', value)}
                      min={-100}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  <Box>
                    <Typography variant="body2">Saturation</Typography>
                    <Slider
                      value={saturation}
                      onChange={(e, value) => handleFilterChange('saturation', value)}
                      min={-100}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  <Box>
                    <Typography variant="body2">Flou</Typography>
                    <Slider
                      value={blur}
                      onChange={(e, value) => handleFilterChange('blur', value)}
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  <Box>
                    <Typography variant="body2">Sepia</Typography>
                    <Slider
                      value={sepia}
                      onChange={(e, value) => handleFilterChange('sepia', value)}
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  <FormControl>
                    <Select
                      multiple
                      value={[
                        ...(grayscale ? ['grayscale'] : []),
                        ...(invert ? ['invert'] : [])
                      ]}
                      onChange={(e) => {
                        const values = e.target.value;
                        const newGrayscale = values.includes('grayscale');
                        const newInvert = values.includes('invert');
                        
                        // Mettre à jour les états
                        setGrayscale(newGrayscale);
                        setInvert(newInvert);
                        
                        // Appliquer les changements de filtres
                        handleFilterChange('grayscale', newGrayscale);
                        handleFilterChange('invert', newInvert);
                      }}
                    >
                      <MenuItem value="grayscale">Noir et blanc</MenuItem>
                      <MenuItem value="invert">Inverser les couleurs</MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetImage}
                    sx={{ mt: 2 }}
                  >
                    Réinitialiser l'image
                  </Button>
                </Box>
              )}

              {/* Remplissage */}
              {selectedObject.type !== 'image' && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography variant="subtitle2">Remplissage</Typography>
                  <FormControl fullWidth>
                    <InputLabel>Type de remplissage</InputLabel>
                    <Select
                      value={gradientType}
                      onChange={(e) => handleGradientChange(e.target.value)}
                      label="Type de remplissage"
                    >
                      <MenuItem value="none">Aucun</MenuItem>
                      <MenuItem value="color">Couleur</MenuItem>
                      <MenuItem value="linear">Dégradé linéaire</MenuItem>
                      <MenuItem value="radial">Dégradé radial</MenuItem>
                    </Select>
                  </FormControl>

                  {gradientType === 'none' ? (
                    <TextField
                      label="Couleur"
                      type="color"
                      value={color}
                      onChange={handleColorChange}
                      fullWidth
                      size="small"
                    />
                  ) : gradientType !== 'transparent' && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <TextField
                        label="Couleur 1"
                        type="color"
                        value={gradientColor1}
                        onChange={(e) => {
                          setGradientColor1(e.target.value);
                          handleGradientChange(gradientType);
                        }}
                        size="small"
                      />
                      <TextField
                        label="Couleur 2"
                        type="color"
                        value={gradientColor2}
                        onChange={(e) => {
                          setGradientColor2(e.target.value);
                          handleGradientChange(gradientType);
                        }}
                        size="small"
                      />
                    </Box>
                  )}
                </Box>
              )}

              {/* Ombre */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="subtitle2">Ombre</Typography>
                <TextField
                  label="Couleur"
                  type="color"
                  value={shadowColor}
                  onChange={(e) => {
                    setShadowColor(e.target.value);
                    handleShadowChange();
                  }}
                  size="small"
                />
                <TextField
                  label="Flou"
                  type="number"
                  value={shadowBlur}
                  onChange={(e) => {
                    setShadowBlur(parseInt(e.target.value, 10));
                    handleShadowChange();
                  }}
                  size="small"
                  inputProps={{ min: 0 }}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Décalage X"
                    type="number"
                    value={shadowOffsetX}
                    onChange={(e) => {
                      setShadowOffsetX(parseInt(e.target.value, 10));
                      handleShadowChange();
                    }}
                    size="small"
                  />
                  <TextField
                    label="Décalage Y"
                    type="number"
                    value={shadowOffsetY}
                    onChange={(e) => {
                      setShadowOffsetY(parseInt(e.target.value, 10));
                      handleShadowChange();
                    }}
                    size="small"
                  />
                </Box>
              </Box>

              {/* Texte */}
              {selectedObject && selectedObject.type === 'textbox' && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography variant="subtitle2">Texte</Typography>
                  <TextField
                    label="Contenu"
                    value={text}
                    onChange={handleTextChange}
                    multiline
                    rows={3}
                    size="small"
                  />
                  <TextField
                    label="Taille de police"
                    type="number"
                    value={fontSize}
                    onChange={handleFontSizeChange}
                    size="small"
                    inputProps={{ min: 1 }}
                  />
                  <FormControl size="small">
                    <InputLabel>Police</InputLabel>
                    <Select
                      value={fontFamily}
                      onChange={handleFontFamilyChange}
                      label="Police"
                    >
                      {fontFamilies.map(font => (
                        <MenuItem key={font} value={font}>
                          {font}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Couleur"
                    type="color"
                    value={color}
                    onChange={handleColorChange}
                    size="small"
                  />
                </Box>
              )}

              {/* Texte dans la forme */}
              <Button
                variant="outlined"
                startIcon={<TextFieldsIcon />}
                onClick={handleAddText}
              >
                Ajouter du texte
              </Button>

              <Divider />
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default Settings;
