import React, { useState, useCallback, useMemo } from 'react';
import { Box, IconButton, Button, Popover, useMediaQuery, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReplayIcon from '@mui/icons-material/Replay';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EditIcon from '@mui/icons-material/Edit';
import ImageEditorModal from '../../ImageEditor/ImageEditorModal';

const SOCIAL_MEDIA_CONFIG = {
  facebook: {
    icon: <FacebookIcon />,
    isAvailable: true,
    label: 'Facebook'
  },
  instagram: {
    icon: <InstagramIcon />,
    isAvailable: true,
    label: 'Instagram'
  },
  twitter: {
    icon: <TwitterIcon />,
    isAvailable: false,
    label: 'Twitter'
  },
  linkedin: {
    icon: <LinkedInIcon />,
    isAvailable: false,
    label: 'LinkedIn'
  }
};

const SocialMediaButton = ({ icon, selected, disabled, onClick, label }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    sx={{
      color: selected ? 'primary.main' : disabled ? 'grey.400' : 'grey.700',
      border: selected ? 2 : 1,
      borderColor: selected ? 'primary.main' : 'grey.300',
      borderRadius: 2,
      p: 1,
      '&:hover': {
        borderColor: selected ? 'primary.main' : 'grey.400',
      },
      position: 'relative'
    }}
  >
    {icon}
    {disabled && (
      <Box
        sx={{
          position: 'absolute',
          bottom: -20,
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '0.7rem',
          color: 'grey.500'
        }}
      >
        Non lié
      </Box>
    )}
  </IconButton>
);

function CardActionsBottomSection({ 
  isEditing, 
  isPublished, 
  isPublishing, 
  onPublishToFacebook, 
  onSchedule, 
  onRegenerate, 
  selectedDate, 
  isMobile, 
  selectedPlatforms,
  onPlatformsChange,
  content,
  mediaFiles,
  userId,
  draftId,
  onUpdateMedia
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const isCompactView = useMediaQuery('(max-width: 845px)');

  const handleOpenPopover = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isPopoverOpen = Boolean(anchorEl);

  const formattedDate = useMemo(() => {
    if (!selectedDate) return 'Non défini';
    
    return new Date(selectedDate.seconds * 1000).toLocaleString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }, [selectedDate]);

  const handleChange = useCallback((platform) => {
    if (!selectedPlatforms || !onPlatformsChange) return;
    
    const newPlatforms = {
      ...selectedPlatforms,
      [platform]: !selectedPlatforms[platform]
    };

    // Vérification pour Instagram
    if (platform === 'instagram' && !mediaFiles?.length && newPlatforms.instagram) {
      newPlatforms.instagram = false;
      alert("Instagram nécessite au moins un média pour publier.");
    }

    onPlatformsChange(newPlatforms);
  }, [selectedPlatforms, onPlatformsChange, mediaFiles]);

  const handlePublish = useCallback(async () => {
    try {
      if (!selectedPlatforms || !Object.values(selectedPlatforms).some(Boolean)) {
        console.error("Aucune plateforme sélectionnée");
        return;
      }

      if (onPublishToFacebook) {
        await onPublishToFacebook();
      }
    } catch (error) {
      console.error("Erreur de publication:", error);
    }
  }, [selectedPlatforms, onPublishToFacebook]);

  const isPublishDisabled = useMemo(() => {
    return (
      isPublishing || 
      isPublished || 
      isEditing || 
      !Object.values(selectedPlatforms || {}).some(Boolean) ||
      (selectedPlatforms?.instagram && !mediaFiles?.length)
    );
  }, [isPublishing, isPublished, isEditing, selectedPlatforms, mediaFiles]);

  const handleEditImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageEditorOpen(true);
  };

  const handleSaveEditedImage = async (editedImageUrl) => {
    if (onUpdateMedia && draftId) {
      const updatedMediaFiles = mediaFiles.map(file => 
        file.url === selectedImage ? { ...file, url: editedImageUrl } : file
      );
      await onUpdateMedia(draftId, updatedMediaFiles);
    }
    setIsImageEditorOpen(false);
    setSelectedImage(null);
  };

  const SocialMediaButtons = useCallback(() => (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {Object.entries(SOCIAL_MEDIA_CONFIG).map(([platform, config]) => (
        <SocialMediaButton
          key={platform}
          icon={config.icon}
          selected={selectedPlatforms?.[platform]}
          onClick={() => handleChange(platform)}
          disabled={!config.isAvailable}
          label={config.label}
        />
      ))}
    </Box>
  ), [selectedPlatforms, handleChange]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
          width: '100%',
          padding: '0 16px 16px',
        }}
      >
        {/* Section gauche */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isCompactView ? (
            <>
              <IconButton color="primary" onClick={handleOpenPopover}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ p: 2 }}>
                  <SocialMediaButtons />
                </Box>
              </Popover>
            </>
          ) : (
            <SocialMediaButtons />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handlePublish}
            disabled={isPublishDisabled}
          >
            {isPublishing ? 'Publication...' : isPublished ? 'Publié' : 'Publier'}
          </Button>
        </Box>

        {/* Section droite */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {mediaFiles?.map((file, index) => (
            <IconButton
              key={index}
              color="primary"
              onClick={() => handleEditImage(file.url)}
              sx={{ position: 'relative' }}
            >
              <EditIcon />
            </IconButton>
          ))}
          {isCompactView ? (
            isEditing && (
              <IconButton
                color="primary"
                onClick={onRegenerate}
                disabled={isPublished || isPublishing || isEditing}
              >
                <ReplayIcon />
              </IconButton>
            )
          ) : (
            isEditing && (
              <Button
                variant="outlined"
                color="primary"
                onClick={onRegenerate}
                disabled={isPublished || isPublishing || isEditing}
                startIcon={<ReplayIcon />}
              >
                Régénérer
              </Button>
            )
          )}
          <Chip 
            icon={<AccessTimeIcon />}
            label={`Date prévue : ${formattedDate}`} 
            color="default" 
            variant="outlined" 
            sx={{ ml: 1 }} 
          />
        </Box>
      </Box>

      <ImageEditorModal
        open={isImageEditorOpen}
        onClose={() => setIsImageEditorOpen(false)}
        imageUrl={selectedImage}
        onSave={handleSaveEditedImage}
      />
    </>
  );
}

export default CardActionsBottomSection;

