import React from 'react';
import ApexChart from './ApexChart';
import { useTheme } from '@mui/material/styles';

const BarChart = ({ data, title, subtitle, height = 350, horizontal = false }) => {
  const theme = useTheme();
  
  // Transformer les données au format attendu par ApexCharts
  const categories = data.slice(1).map(item => item[0]);
  const series = [
    {
      name: data[0][1],
      data: data.slice(1).map(item => item[1]),
    },
  ];
  
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: horizontal,
        columnWidth: '50%',
        borderRadius: 6,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: data[0][1],
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      style: {
        fontSize: '14px',
        color: theme.palette.text.secondary,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return <ApexChart type="bar" height={height} series={series} options={options} />;
};

export default BarChart; 