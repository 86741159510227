import React, { useState } from 'react';
import { Box, Toolbar, useMediaQuery, IconButton, AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerComponent from './components/DrawerComponent';

function DashboardPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  // État pour le menu réduit manuellement
  const [manualCollapsed, setManualCollapsed] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Fonction pour basculer le mode réduit du menu
  const toggleCollapse = () => {
    setManualCollapsed(!manualCollapsed);
  };

  return (
    <Box
      display="flex"
      height="100vh"
      overflow="hidden"
    >
      {/* AppBar visible uniquement en mode mobile */}
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: 'white',
            color: 'text.primary',
            boxShadow: '0 1px 10px rgba(0,0,0,0.08)',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="ouvrir le menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {/* Logo ou titre pour mobile */}
            <Box sx={{ 
              color: 'primary.main', 
              fontWeight: 'bold',
              fontSize: '1.25rem',
              letterSpacing: '0.5px'
            }}>
              Visiblia
            </Box>
          </Toolbar>
        </AppBar>
      )}
      
      {/* Drawer principal avec les nouvelles propriétés */}
      <DrawerComponent
        isMobile={isMobile}
        mobileOpen={mobileOpen}
        onDrawerToggle={handleDrawerToggle}
        onLogout={handleLogout}
        manualCollapsed={manualCollapsed}
        toggleCollapse={toggleCollapse}
      />

      {/* Contenu principal avec gestion du scroll local */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
          pt: isMobile ? '64px' : 0, // Espace pour la barre d'application mobile
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardPage;
