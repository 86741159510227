import React from 'react';
import ModernSidebar from '../../../components/Sidebar/ModernSidebar';

const DrawerComponent = ({ 
  isMobile, 
  mobileOpen, 
  onDrawerToggle, 
  onLogout,
  manualCollapsed,
  toggleCollapse
}) => {
  return (
    <ModernSidebar
      isMobileSidebarOpen={mobileOpen}
      onSidebarClose={onDrawerToggle}
      isSidebarOpen={!isMobile}
      onLogout={onLogout}
      manualCollapsed={manualCollapsed}
      toggleCollapse={toggleCollapse}
    />
  );
};

export default DrawerComponent;