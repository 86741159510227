import React from "react";
import {
  ListItemIcon,
  ListItem,
  List,
  styled,
  ListItemText,
  useTheme,
  ListItemButton,
  Tooltip
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// Type pour les éléments de navigation
const NavItem = ({ item, level, onClick, isCollapsed }) => {
  const Icon = item.icon;
  const theme = useTheme();
  const location = useLocation();
  const pathDirect = location.pathname;
  
  const ListItemStyled = styled(ListItem)(() => ({
    padding: 0,
    ".MuiButtonBase-root": {
      whiteSpace: "nowrap",
      marginBottom: "2px",
      padding: isCollapsed ? "10px 6px" : "8px 10px", // Ajustement du padding si réduit
      borderRadius: "8px",
      backgroundColor: level > 1 ? "transparent !important" : "inherit",
      color: theme.palette.text.secondary,
      paddingLeft: isCollapsed ? "6px" : "10px",
      justifyContent: isCollapsed ? "center" : "flex-start", // Centrer si réduit
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
      "&.Mui-selected": {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
    },
  }));

  // Si mode réduit, on affiche seulement l'icône avec un tooltip
  if (isCollapsed) {
    return (
      <List component="div" disablePadding key={item.id}>
        <ListItemStyled>
          <Tooltip title={item.title} placement="right">
            <ListItemButton
              component={Link}
              to={item.href}
              disabled={item.disabled}
              selected={pathDirect === item.href}
              target={item.external ? "_blank" : ""}
              onClick={onClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 'auto', // Pour bien centrer
                  p: "3px 0",
                  color: "inherit",
                }}
              >
                {Icon && <Icon />}
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItemStyled>
      </List>
    );
  }

  // Mode normal avec texte et icône
  return (
    <List component="div" disablePadding key={item.id}>
      <ListItemStyled>
        <ListItemButton
          component={Link}
          to={item.href}
          disabled={item.disabled}
          selected={pathDirect === item.href}
          target={item.external ? "_blank" : ""}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              minWidth: "36px",
              p: "3px 0",
              color: "inherit",
            }}
          >
            {Icon && <Icon />}
          </ListItemIcon>
          <ListItemText>
            <>{item.title}</>
          </ListItemText>
        </ListItemButton>
      </ListItemStyled>
    </List>
  );
};

export default NavItem; 