import React from 'react';
import ApexChart from './ApexChart';
import { useTheme } from '@mui/material/styles';

const PieChart = ({ data, title, height = 350, donut = true }) => {
  const theme = useTheme();
  
  // Transformer les données au format attendu par ApexCharts
  const series = data.slice(1).map(item => item[1]);
  const labels = data.slice(1).map(item => item[0]);
  
  const options = {
    chart: {
      type: donut ? 'donut' : 'pie',
    },
    labels,
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: !donut,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return <ApexChart type={donut ? 'donut' : 'pie'} height={height} series={series} options={options} />;
};

export default PieChart; 