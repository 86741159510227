import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// Import du thème modernize et de la fonction pour créer un thème selon le mode
import modernizeTheme, { createAppTheme } from './config/style/modernize-theme';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider, useThemeMode } from './contexts/ThemeContext';

import HomePage from './pages/HomePage/HomePage';
import SignUpPage from './pages/register/SignUpPage';
import LoginPage from './pages/register/LoginPage';
import CompanyFormPage from './pages/register/CompanyFormPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ProfilePage from './pages/Dashboard/sections/Profile/ProfilePage';
import PostHistoryPage from './pages/Dashboard/sections/PostHistory/PostHistoryPage';
import NewPostPage from './pages/Dashboard/sections/NewPost/NewPostPage';
import ProtectedRoute from './components/ProtectedRoute';
import { loadFacebookSdk } from './services/socialNetworks/facebook/facebookSdk';
import { NotificationProvider } from './components/Notification/Notification';
import CalendarPage from './pages/Dashboard/sections/Calendar/CalendarPage';
import HomeAppPage from './pages/Dashboard/sections/Home/HomeAppPage';
import ThemeTestPage from './pages/ThemeTestPage';

// Composant qui utilise le contexte du thème pour choisir le bon thème MUI
const ThemedApp = () => {
  const { mode } = useThemeMode();
  // Créer le thème en fonction du mode (light/dark)
  const currentTheme = createAppTheme(mode);

  useEffect(() => {
    loadFacebookSdk();
  }, []);

  return (
    <MuiThemeProvider theme={currentTheme}>
      <NotificationProvider>
        <CssBaseline />
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflow: 'auto' }}>
          <Router>
            <Routes>
              {/* Routes publiques */}
              <Route path="/" element={<HomePage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/company-form" element={<CompanyFormPage />} />
              <Route path="/theme-test" element={<ThemeTestPage />} />
              {/* Routes protégées du tableau de bord */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}>
                <Route index element={<Navigate to="home" replace />} />
                <Route path="home" element={<HomeAppPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="post-history" element={<PostHistoryPage />} />
                <Route path="new-post" element={<NewPostPage />} />
                <Route path="calendar" element={<CalendarPage />} />
              </Route>
            </Routes>
          </Router>
        </div>
      </NotificationProvider>
    </MuiThemeProvider>
  );
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <ThemedApp />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
