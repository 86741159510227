import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { uniqueId } from 'lodash';

const Menuitems = [
  {
    navlabel: true,
    subheader: "Principal",
  },
  {
    id: uniqueId(),
    title: "Tableau de bord",
    icon: DashboardIcon,
    href: "/dashboard/home",
  },
  {
    id: uniqueId(),
    title: "Nouvelle publication",
    icon: PostAddIcon,
    href: "/dashboard/new-post",
  },
  {
    navlabel: true,
    subheader: "Gestion",
  },
  {
    id: uniqueId(),
    title: "Calendrier",
    icon: CalendarMonthIcon,
    href: "/dashboard/calendar",
  },
  {
    id: uniqueId(),
    title: "Historique",
    icon: HistoryIcon,
    href: "/dashboard/post-history",
  },
  {
    navlabel: true,
    subheader: "Compte",
  },
  {
    id: uniqueId(),
    title: "Mon profil",
    icon: AccountCircleIcon,
    href: "/dashboard/profile",
  },
];

export default Menuitems; 