import React, { useEffect, useState } from 'react';
import { 
    List, 
    ListItem, 
    ListItemText, 
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Box,
    Menu,
    MenuItem,
    ListItemIcon,
    Paper,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ContextMenuCanvas from './ContextMenuCanvas';

function Layers({ canvas }) {
    const [layers, setLayers] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [dropTarget, setDropTarget] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingName, setEditingName] = useState('');

    useEffect(() => {
        if (!canvas) return;

        const updateLayers = () => {
            const objects = canvas.getObjects().reverse();
            setLayers(objects.map(obj => ({
                id: obj.id || Math.random().toString(36).substr(2, 9),
                type: obj.type,
                visible: obj.visible !== false,
                name: obj.customName || getObjectName(obj),
                object: obj
            })));
        };

        // Gestionnaire de clic droit sur le canvas
        const handleCanvasContextMenu = (event) => {
            const target = canvas.findTarget(event.e);
            if (target) {
                event.e.preventDefault();
                const objects = canvas.getObjects();
                const index = objects.length - 1 - objects.indexOf(target);
                
                console.log('Canvas context menu triggered', { target, index });
                
                canvas.setActiveObject(target);
                canvas.requestRenderAll();
                
                setSelectedLayer(index);
                setContextMenu({
                    mouseX: event.e.pageX,
                    mouseY: event.e.pageY
                });
            }
        };

        canvas.on('mouse:down', (event) => {
            if (event.button === 3) { // Clic droit
                handleCanvasContextMenu(event);
            }
        });

        canvas.on('object:added', updateLayers);
        canvas.on('object:removed', updateLayers);
        canvas.on('object:modified', updateLayers);
        canvas.on('object:moving', updateLayers);
        canvas.on('object:scaling', updateLayers);
        canvas.on('object:rotated', updateLayers);
        canvas.on('object:skewed', updateLayers);
        canvas.on('layer:changed', updateLayers);

        updateLayers();

        return () => {
            canvas.off('object:added', updateLayers);
            canvas.off('object:removed', updateLayers);
            canvas.off('object:modified', updateLayers);
            canvas.off('object:moving', updateLayers);
            canvas.off('object:scaling', updateLayers);
            canvas.off('object:rotated', updateLayers);
            canvas.off('object:skewed', updateLayers);
            canvas.off('layer:changed', updateLayers);
            canvas.off('mouse:down');
        };
    }, [canvas]);

    const getObjectName = (obj) => {
        switch (obj.type) {
            case 'textbox':
                return `Texte: ${obj.text.substring(0, 15)}${obj.text.length > 15 ? '...' : ''}`;
            case 'image':
                return 'Image';
            case 'rect':
                return 'Rectangle';
            case 'circle':
                return 'Cercle';
            default:
                return obj.type;
        }
    };

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('Menu contextuel déclenché', { x: event.pageX, y: event.pageY });
        
        const objects = canvas.getObjects();
        const object = objects[objects.length - 1 - index];
        
        if (object) {
            console.log('Objet sélectionné:', object);
            canvas.discardActiveObject();
            canvas.setActiveObject(object);
            canvas.requestRenderAll();
            
            setSelectedLayer(index);
            setContextMenu({
                mouseX: event.pageX,
                mouseY: event.pageY
            });
        }
    };

    const handleClose = () => {
        console.log('Fermeture du menu contextuel');
        setContextMenu(null);
        setSelectedLayer(null);
    };

    const handleDragStart = (e, index) => {
        if (e.dataTransfer.setDragImage) {
            const dragElement = document.createElement('div');
            dragElement.textContent = layers[index].name;
            dragElement.style.position = 'absolute';
            dragElement.style.top = '-1000px';
            document.body.appendChild(dragElement);
            e.dataTransfer.setDragImage(dragElement, 0, 0);
            setTimeout(() => document.body.removeChild(dragElement), 0);
        }

        setDraggedIndex(index);
        e.dataTransfer.setData('application/json', JSON.stringify({ index }));
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        
        if (index !== draggedIndex) {
            setDropTarget(index);
        }
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        const dragData = e.dataTransfer.getData('application/json');
        const { index: dragIndex } = JSON.parse(dragData);

        if (dragIndex === dropIndex) return;

        const objects = canvas.getObjects();
        const itemToMove = objects[objects.length - 1 - dragIndex];
        
        objects.splice(objects.length - 1 - dragIndex, 1);
        objects.splice(objects.length - dropIndex, 0, itemToMove);

        canvas.remove(...objects);
        canvas.add(...objects);
        canvas.requestRenderAll();

        setDraggedIndex(null);
        setDropTarget(null);
    };

    const handleDragEnd = (e) => {
        e.target.style.opacity = '1';
        setDraggedIndex(null);
        setDropTarget(null);
    };

    const handleCopy = () => {
        if (selectedLayer === null) return;
        const objects = canvas.getObjects();
        const obj = objects[objects.length - 1 - selectedLayer];
        if (obj) {
            obj.clone((cloned) => {
                cloned.set({
                    left: obj.left + 10,
                    top: obj.top + 10
                });
                canvas.add(cloned);
                canvas.renderAll();
            });
        }
        handleClose();
    };

    const handleCut = () => {
        if (selectedLayer === null) return;
        const objects = canvas.getObjects();
        const obj = objects[objects.length - 1 - selectedLayer];
        if (obj) {
            canvas.remove(obj);
            canvas._clipboard = obj;
        }
        handleClose();
    };

    const handleDelete = () => {
        if (selectedLayer === null) return;
        const objects = canvas.getObjects();
        const obj = objects[objects.length - 1 - selectedLayer];
        if (obj) {
            canvas.remove(obj);
        }
        handleClose();
    };

    const handleBringToFront = () => {
        if (selectedLayer === null) return;
        const objects = canvas.getObjects();
        const obj = objects[objects.length - 1 - selectedLayer];
        if (obj) {
            obj.bringToFront();
            canvas.discardActiveObject();
            canvas.setActiveObject(obj);
            canvas.fire('object:modified', { target: obj });
            canvas.requestRenderAll();
        }
        handleClose();
    };

    const handleSendToBack = () => {
        if (selectedLayer === null) return;
        const objects = canvas.getObjects();
        const obj = objects[objects.length - 1 - selectedLayer];
        if (obj) {
            obj.sendToBack();
            canvas.discardActiveObject();
            canvas.setActiveObject(obj);
            canvas.fire('object:modified', { target: obj });
            canvas.requestRenderAll();
        }
        handleClose();
    };

    const handleSelectAll = () => {
        canvas.discardActiveObject();
        const sel = new canvas.fabric.ActiveSelection(canvas.getObjects(), {
            canvas: canvas,
        });
        canvas.setActiveObject(sel);
        canvas.requestRenderAll();
        handleClose();
    };

    const handleStartEditing = (index) => {
        setEditingIndex(index);
        setEditingName(layers[index].name);
        handleClose();
    };

    const handleNameChange = (e) => {
        setEditingName(e.target.value);
    };

    const handleNameSubmit = (e) => {
        if (e.key === 'Enter' && editingIndex !== null) {
            const objects = canvas.getObjects();
            const object = objects[objects.length - 1 - editingIndex];
            object.customName = editingName.trim();
            setLayers(prev => {
                const newLayers = [...prev];
                newLayers[editingIndex] = {
                    ...newLayers[editingIndex],
                    name: editingName.trim()
                };
                return newLayers;
            });
            canvas.requestRenderAll();
            setEditingIndex(null);
        } else if (e.key === 'Escape') {
            setEditingIndex(null);
        }
    };

    const handleLayerClick = (index) => {
        const objects = canvas.getObjects();
        const object = objects[objects.length - 1 - index];
        canvas.discardActiveObject();
        canvas.setActiveObject(object);
        canvas.requestRenderAll();
    };

    const toggleVisibility = (index) => {
        if (!canvas) return;
        const objects = canvas.getObjects();
        const obj = objects[index];
        if (obj) {
            obj.visible = !obj.visible;
            canvas.renderAll();
        }
    };

    return (
        <Box 
            sx={{ 
                width: '100%',
                height: '100%',
                position: 'relative'
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                console.log('Événement contextuel sur Box principal empêché');
            }}
        >
            {layers.length === 0 ? (
                <Typography variant="body2" color="text.secondary" align="center">
                    Aucun élément sur le canvas
                </Typography>
            ) : (
                <List 
                    dense 
                    component={Paper} 
                    variant="outlined" 
                    sx={{ 
                        bgcolor: 'background.paper',
                        position: 'relative',
                        zIndex: 1,
                        '& .MuiListItem-root': {
                            userSelect: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: 'rgba(0, 0, 0, 0.04)'
                            },
                            '&.dragging': {
                                bgcolor: 'rgba(0, 0, 0, 0.08)',
                                opacity: 0.5
                            },
                            '&.drop-target': {
                                borderTop: '2px solid #1976d2'
                            },
                            '&.selected': {
                                bgcolor: 'rgba(25, 118, 210, 0.08)'
                            }
                        }
                    }}
                >
                    {layers.map((layer, index) => {
                        const objects = canvas.getObjects();
                        const isSelected = objects[objects.length - 1 - index] === canvas.getActiveObject();
                        
                        return (
                            <ListItem
                                key={layer.id}
                                draggable={editingIndex !== index}
                                className={`
                                    ${draggedIndex === index ? 'dragging' : ''}
                                    ${dropTarget === index ? 'drop-target' : ''}
                                    ${isSelected ? 'selected' : ''}
                                `}
                                onClick={() => handleLayerClick(index)}
                                onContextMenu={(e) => handleContextMenu(e, index)}
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={(e) => handleDragOver(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragEnd={() => {
                                    setDraggedIndex(null);
                                    setDropTarget(null);
                                }}
                                onDoubleClick={(e) => {
                                    e.stopPropagation();
                                    handleStartEditing(index);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <DragHandleIcon 
                                    sx={{ 
                                        mr: 1,
                                        color: 'text.secondary',
                                        cursor: 'grab',
                                        '&:active': {
                                            cursor: 'grabbing'
                                        }
                                    }}
                                />
                                {editingIndex === index ? (
                                    <TextField
                                        value={editingName}
                                        onChange={handleNameChange}
                                        onKeyDown={handleNameSubmit}
                                        onBlur={() => setEditingIndex(null)}
                                        autoFocus
                                        size="small"
                                        fullWidth
                                        sx={{ mr: 4 }}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                ) : (
                                    <ListItemText primary={layer.name} />
                                )}
                                <ListItemSecondaryAction>
                                    <IconButton 
                                        edge="end" 
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleVisibility(index);
                                        }}
                                    >
                                        {layer.visible ? 
                                            <VisibilityIcon fontSize="small" /> : 
                                            <VisibilityOffIcon fontSize="small" />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            )}

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                sx={{
                    zIndex: 9999,
                    '& .MuiPaper-root': {
                        boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
                        minWidth: 200
                    }
                }}
            >
                <MenuItem onClick={handleCopy}>
                    <ListItemIcon>
                        <ContentCopyOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Dupliquer</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCut}>
                    <ListItemIcon>
                        <ContentCutOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Couper</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Supprimer</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleBringToFront}>
                    <ListItemIcon>
                        <VerticalAlignTopOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Mettre au premier plan</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleSendToBack}>
                    <ListItemIcon>
                        <VerticalAlignBottomOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Mettre en arrière-plan</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default Layers; 